import { Fragment, useEffect, useState } from "react";
import { Card, Button, Avatar, Image, Radio, Alert } from "antd";
import {
  Participants,
  SessionTypeResponse,
} from "../../../../organisms/MyJob/ducks/types";
import { getInitials } from "../../../../config/constants";
import { MenuSelectedIcon } from "../../../../atoms/CustomIcons";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
};

export default function Step1(props: PropsType) {
  const { setCurrent, sessionData } = props;
  const [participantId, setParticipantId] = useState("");

  useEffect(() => {
    if (sessionData?.center_person) {
      setParticipantId(sessionData?.center_person);
    } else {
      setParticipantId("");
    }
  }, [sessionData]);

  return (
    <Card className="fp_layout_content session_card">
      <div className="cardContent">
        {sessionData?.last_session_screen > 1 && (
          <Alert
            message="This activity is completed."
            icon={<MenuSelectedIcon />}
            type="success"
            showIcon
            style={{ marginBottom: 16, border: 0 }}
          />
        )}
        <div className="select_individual_head">
          <div className="individual_left">
            <h4>Select one individual from the participants</h4>
            <p>Decide who you put in the center of the flower.</p>
          </div>
          <div className="individual_right">
            <Button className="icon_grey_btn" disabled>
              <Image
                src="/images/session/magic-icon.svg"
                preview={false}
                style={{ opacity: 0.5 }}
              />
              Select randomly
            </Button>
          </div>
        </div>

        <div className="participants_card">
          <Radio.Group buttonStyle="solid" value={participantId}>
            {sessionData?.participants?.map((e: Participants) => (
              <Fragment key={e?.id}>
                <Radio.Button value={e?.id} disabled>
                  {e?.image ? (
                    <div className="participant_avatar">
                      <Avatar src={e?.image?.url} />
                    </div>
                  ) : (
                    <div className="participant_avatar">
                      <Avatar style={{ fontSize: 15 }}>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    </div>
                  )}
                  <div className="participant_info">
                    <h6>{`${e?.first_name ?? ""} ${e?.last_name ?? ""}`}</h6>
                    <p>
                      {e?.job?.name ?? (
                        <span style={{ opacity: 0.5 }}>No job title</span>
                      )}
                    </p>
                  </div>
                  <div className="participant_check">
                    <Image
                      src="/images/session/checked-icon.svg"
                      preview={false}
                      width={18}
                      height={18}
                    />
                  </div>
                </Radio.Button>
              </Fragment>
            ))}
          </Radio.Group>

          <div className="steps_btn_wrapper">
            <Button
              htmlType="button"
              className="icon_dark_btn"
              type="primary"
              size="large"
              onClick={() => setCurrent(1)}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
