import {
  Leaf3,
  Leaf4,
  Leaf6,
  Leaf8,
  Leaf10,
  Leaf12,
  Leaf14,
  Leaf18,
  Leaf20,
} from "../../../../../atoms/CustomIcons";

export const flowerMapGen: {
  [key: number]: {
    class: string;
    petalShape: React.ReactElement;
  };
} = {
  1: {
    class: "flower_leaf_3",
    petalShape: <Leaf3 />,
  },
  2: {
    class: "flower_leaf_3",
    petalShape: <Leaf3 />,
  },
  3: {
    class: "flower_leaf_3",
    petalShape: <Leaf3 />,
  },
  4: {
    class: "flower_leaf_4",
    petalShape: <Leaf4 />,
  },
  5: {
    class: "flower_leaf_6",
    petalShape: <Leaf6 />,
  },
  6: {
    class: "flower_leaf_6",
    petalShape: <Leaf6 />,
  },
  7: {
    class: "flower_leaf_8",
    petalShape: <Leaf8 />,
  },
  8: {
    class: "flower_leaf_8",
    petalShape: <Leaf8 />,
  },
  9: {
    class: "flower_leaf_10",
    petalShape: <Leaf10 />,
  },
  10: {
    class: "flower_leaf_10",
    petalShape: <Leaf10 />,
  },
  11: {
    class: "flower_leaf_12",
    petalShape: <Leaf12 />,
  },
  12: {
    class: "flower_leaf_12",
    petalShape: <Leaf12 />,
  },
  13: {
    class: "flower_leaf_14",
    petalShape: <Leaf14 />,
  },
  14: {
    class: "flower_leaf_14",
    petalShape: <Leaf14 />,
  },
  15: {
    class: "flower_leaf_18",
    petalShape: <Leaf18 />,
  },
  16: {
    class: "flower_leaf_18",
    petalShape: <Leaf18 />,
  },
  17: {
    class: "flower_leaf_18",
    petalShape: <Leaf18 />,
  },
  18: {
    class: "flower_leaf_18",
    petalShape: <Leaf18 />,
  },
  19: {
    class: "flower_leaf_20",
    petalShape: <Leaf20 />,
  },
  20: {
    class: "flower_leaf_20",
    petalShape: <Leaf20 />,
  },
  21: {
    class: "flower_leaf_20",
    petalShape: <Leaf20 />,
  },
  22: {
    class: "flower_leaf_20",
    petalShape: <Leaf20 />,
  },
  23: {
    class: "flower_leaf_20",
    petalShape: <Leaf20 />,
  },
  24: {
    class: "flower_leaf_20",
    petalShape: <Leaf20 />,
  },
};
