import { useEffect, useState } from "react";
import { Button, Image, Dropdown, Card, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import {
  EmployeesIcon,
  JobsIcon,
  RolesIcon,
  EditIcon,
} from "../../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getCompanyDetails } from "../../../CompanySettings/ducks/actions";
import CompanyDelete from "../../../../atoms/CompanyDelete";
import { Popup } from "../../../../atoms/Popup";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import { deleteCompany } from "../../../Companies/ducks/services";
import { DeleteFilled } from "@ant-design/icons";

const { Meta } = Card;
type PropsType = {
  companyId: string;
  jobData?: any;
};

export default function Header(props: PropsType) {
  const { companyId } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const handleDeleteCompany = async () => {
    setDeleteLoading(true);
    deleteCompany(companyId as string)
      .then(() => {
        SuccessMessage("company deleted.");
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
        navigate("/companies");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <CompanyDelete
        title="Confirm deletion"
        heading="Delete company?"
        paragraph_1="This action will permanently delete all data for this company and cannot be undone."
        paragraph_2="Confirm this operation by typing the company name below."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete company"
        btnApi={handleDeleteCompany}
        loading={deleteLoading}
        companyName={companyData?.name}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  const categories = [
    {
      key: "1",
      label: (
        <Button
          onClick={() => navigate(`/create-job/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <JobsIcon className="darkIcon" />
          {companyData?.label_position === "Job" ? "Create Job" : "Create Seat"}
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => navigate(`/create-role/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <RolesIcon className="darkIcon" />
          Create Role
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={() => navigate(`/add-employees/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <EmployeesIcon className="darkIcon" />
          Create Employee
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          onClick={() => navigate(`/company-settings/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <EditIcon className="darkIcon" /> Edit company info
        </Button>
      ),
    },
    {
      key: "5",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <DeleteFilled className="darkIcon" /> Delete company
        </Button>
      ),
    },
  ];

  const categoriesCompanyAdmin = [
    {
      key: "1",
      label: (
        <Button
          onClick={() => navigate(`/create-job/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <JobsIcon className="darkIcon" />
          {companyData?.label_position === "Job" ? "Create Job" : "Create Seat"}
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => navigate(`/create-role/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <RolesIcon className="darkIcon" />
          Create Role
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={() => navigate(`/add-employees/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <EmployeesIcon className="darkIcon" />
          Create Employee
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          onClick={() => navigate(`/company-settings/${companyId}`)}
          className="noBorder"
          style={{ padding: "0px", width: "100%" }}
        >
          <EditIcon className="darkIcon" /> Edit company info
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  return (
    <div className="fp_main_details">
      <div className="companyInfo">
        <Meta
          avatar={
            companyData?.image?.url ? (
              <Image
                src={companyData?.image?.url}
                width={68}
                height={68}
                preview={false}
              />
            ) : (
              <Avatar size={68} shape="square" />
            )
          }
          title={companyData?.name}
          description={companyData?.location}
          className="headingH2"
        />
      </div>
      {accountData?.type !== "INDIVIDUAL_CONTRIBUTOR" && (
        <div className="actionBtn">
          {/* <Dropdown
            menu={{ items: categories }}
            placement="bottomLeft"
            overlayClassName="full_width_btn"
            disabled={buttonTour}
          >
            <Button className="icon_dark_btn create_header_btn" ref={buttonRef}>
              Create
              <ArrowIcon className="angleIcon" />
            </Button>
          </Dropdown> */}

          <Button
            className="icon_dark_btn"
            onClick={() => navigate(`/create-flower-session/${companyId}`)}
          >
            <img src="/images/fp_flower.svg" alt="" /> Start FP Session
          </Button>

          {accountData?.type === "SUPER_ADMIN" ? (
            <Dropdown
              menu={{ items: categories }}
              trigger={["click"]}
              placement="bottomRight"
              className="actionButton companyDeleteBtn"
            >
              <Button>
                <Image
                  src="/images/vertical_dots.svg"
                  width={3}
                  height={15}
                  alt="vertical dots"
                  preview={false}
                />
              </Button>
            </Dropdown>
          ) : (
            <Dropdown
              menu={{ items: categoriesCompanyAdmin }}
              trigger={["click"]}
              placement="bottomRight"
              className="actionButton companyDeleteBtn"
            >
              <Button>
                <Image
                  src="/images/vertical_dots.svg"
                  width={3}
                  height={15}
                  alt="vertical dots"
                  preview={false}
                />
              </Button>
            </Dropdown>
          )}
        </div>
      )}
      <Popup {...deleteConfirmationPopup} />
    </div>
  );
}
