import { Tabs } from "antd";
import {
  RoleType,
  SessionTypeResponse,
} from "../../../../organisms/MyJob/ducks/types";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import {
  clearFlowerDataBySessionID,
  getFlowerDataBySessionID,
} from "../../../../organisms/MyJob/ducks/actions";
import SelectFpSessionRole from "../Components/SelectFpSessionRole";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step8(props: PropsType) {
  const { setCurrent, sessionId, sessionData, setLoading } = props;
  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useAppDispatch();
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
    }
  }, [sessionId]);

  useEffect(() => {
    dispatch(clearFlowerDataBySessionID());
  }, []);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div className="session_flower">
      {flowerData?.sessionPetals?.length > 0 && (
        <Tabs
          activeKey={activeTab}
          onChange={onTabChange}
          destroyInactiveTabPane={true}
          items={flowerData?.sessionPetals?.map((e: RoleType, i: number) => ({
            key: `${i + 1}`,
            label: (
              <div className={`tab-label fp-tabs-${i + 1}`}>
                <span className="tab-number">{i + 1}</span> {e?.name}
              </div>
            ),
            children: (
              <SelectFpSessionRole
                data={flowerData?.sessionPetals}
                petalTeams={flowerData?.petalTeams}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setCurrent={setCurrent}
                activeData={e}
                activeNumber={i + 1}
                sessionId={sessionId}
                sessionData={sessionData}
                setLoading={setLoading}
              />
            ),
          }))}
        />
      )}
    </div>
  );
}
