import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import KanbanBoard from "../../../../../../molecules/KanbanBoard";
import { Column, Role } from "../../../../../../molecules/KanbanBoard/types";
import {
  addPetalsBySessionId,
  updateSessionById,
} from "../../../../../../organisms/MyJob/ducks/services";
import {
  getFlowerDataBySessionID,
  getPetalsWithRoleDescriptorsBySessionId,
  getSessionById,
} from "../../../../../../organisms/MyJob/ducks/actions";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../Toasts/ErrorMessage";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionId: string;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step3(props: PropsType) {
  const { setCurrent, sessionId, sessionData, setLoading } = props;
  const [roles, setRoles] = useState<Role[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const dispatch = useAppDispatch();
  const roleData = useAppSelector((state) => state?.jobDetail?.roleDataSession);
  const flowerData = useAppSelector(
    (state) => state?.jobDetail?.sessionFlowerData
  );

  const transformData = () => {
    if (
      sessionData?.last_session_screen > 3 &&
      roleData?.length === roles?.length &&
      flowerData?.sessionPetals?.length === columns?.length - 1
    ) {
      setCurrent(4);
    } else {
      setLoading(true);
      const sortedRolesByColumn = columns
        ?.filter((column) => column.id !== "basket") // Filter out "basket" column
        ?.map((column, index) => ({
          index: index,
          role_descriptors_id: roles
            ?.filter((role) => role?.column_id === column?.id)
            ?.sort((a, b) => a.index - b.index)
            ?.map((role) => role?.id), // Sort roles within each column by index
        }));

      const payload = {
        session_id: sessionId,
        petals: sortedRolesByColumn,
      };
      addPetalsBySessionId(payload)
        .then(() => {
          const payload = {
            last_session_screen: 4,
          };
          updateSessionById(payload, sessionId)
            .then(() => {
              setLoading(false);
              setCurrent(4);
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        })
        .catch((e: unknown) => {
          if (e instanceof AxiosError) {
            setLoading(false);
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
          } else {
            setLoading(false);
            ErrorMessage("Something went wrong");
          }
        });
    }
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
      dispatch(getPetalsWithRoleDescriptorsBySessionId(sessionId));
      dispatch(getSessionById(sessionId));
    }
  }, [dispatch, sessionId]);

  return (
    <KanbanBoard
      roleData={roleData}
      setCurrent={setCurrent}
      transformData={transformData}
      roles={roles}
      setRoles={setRoles}
      columns={columns}
      setColumns={setColumns}
      sessionId={sessionId}
      sessionData={sessionData}
      flowerData={flowerData?.sessionPetals}
    />
  );
}
