import { useEffect, useMemo, useState } from "react";
import { Tabs, TabsProps, Image, Card, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon, PencilIcon } from "../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getJobDetails, getSessionById } from "../../MyJob/ducks/actions";
import { RoleType } from "../../MyJob/ducks/types";
import { getCompanyDetails } from "../../CompanySettings/ducks/actions";
import WorksheetGuide from "../../../atoms/WorksheetGuide";
import FullPageLayout from "../../../templates/FullPageLayout";
import RoleForm from "./Components/RoleForm";
import EditJobForm from "./Components/EditJobForm";

export default function WsSessionDetails() {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [openWorksheet, setOpenWorksheet] = useState(false);
  const [defaultTabSelected, setDefaultTabSelected] = useState("");
  const [jobTabsItems, setJobTabsItems] = useState<TabsProps["items"]>([]);
  const url = useLocation();
  const sessionId = url?.pathname.split("/")[2];
  const customArray: TabsProps["items"] = [];
  const sessionData = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.data
  );
  const sessionLoader = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.loading
  );
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionById(sessionId));
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionData?.job_id) {
      dispatch(getJobDetails(sessionData?.job_id));
    }
  }, [sessionData?.job_id]);

  useMemo(() => {
    if (jobDetailData?.detailData?.roles?.length > 0) {
      jobDetailData?.detailData?.roles?.map((e: RoleType, i: number) =>
        customArray.push({
          key: `${i + 1}`,
          label: (
            <div className="tab-label">
              <span className="tab-number">{i + 1}</span> {e?.name}{" "}
              <Image
                src={
                  e?.department
                    ? "/images/session/checked-circle.svg"
                    : "/images/session/unchecked-circle.svg"
                }
                preview={false}
                width={20}
              />
            </div>
          ),
          children: (
            <RoleForm
              data={e}
              setActiveTab={setActiveTab}
              totalLength={jobDetailData?.detailData?.roles?.length}
              activeNumber={i + 1}
              setLoading={setLoading}
              setDefaultTabSelected={setDefaultTabSelected}
              setOpenWorksheet={setOpenWorksheet}
            />
          ),
        })
      );

      const jobItems: TabsProps["items"] = [
        {
          key: "jobTitle",
          label: (
            <span style={{ textAlign: "left", display: "block" }}>
              {companyData?.label_position}{" "}
            </span>
          ),
          disabled: true,
        },
        {
          key: "jobName",
          label: (
            <div className="job_name">
              <PencilIcon className="jobName-icon" />{" "}
              {jobDetailData?.detailData?.name}
            </div>
          ),
          children: (
            <EditJobForm
              setLoading={setLoading}
              setActiveTab={setActiveTab}
              totalLength={jobDetailData?.detailData?.roles?.length}
              activeNumber={jobDetailData?.detailData?.roles?.length + 1}
              setDefaultTabSelected={setDefaultTabSelected}
              setOpenWorksheet={setOpenWorksheet}
              jobName={jobDetailData?.detailData?.name}
            />
          ),
        },
      ];

      const tabItems = [...customArray, ...jobItems];
      setJobTabsItems(tabItems);
    }
  }, [jobDetailData?.detailData?.roles, companyData?.label_position]);

  useEffect(() => {
    if (jobDetailData?.detailData?.company_id) {
      dispatch(getCompanyDetails(jobDetailData?.detailData?.company_id));
    }
  }, [dispatch, jobDetailData?.detailData?.company_id]);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <FullPageLayout
      loading={loading || jobDetailData?.loading || sessionLoader}
    >
      <Card className="fp_layout_title session_title">
        <div className="mainTitle">
          <div className="session_head_left">
            <Button
              className="icon_grey_btn"
              icon={<CloseIcon />}
              onClick={() => navigate(-1)}
            >
              Close
            </Button>
            <h3>Job & role to 14 point worksheet session</h3>
            <div className="session_avatar"></div>
          </div>
        </div>
      </Card>
      <div className="centered_content">
        <div className="fp_layout_content" style={{ height: "auto" }}>
          <div className="editJob">
            <div className="controlPanel">
              <div className="title">
                <h6>Roles</h6>
              </div>
              <div className="role_job_list">
                <Tabs
                  tabPosition="left"
                  items={jobTabsItems}
                  activeKey={activeTab}
                  onChange={onTabChange}
                  destroyInactiveTabPane={true}
                  defaultActiveKey={
                    jobDetailData?.detailData?.roles?.length > 0
                      ? "1"
                      : "newRole"
                  }
                />
              </div>
            </div>
          </div>
          <WorksheetGuide
            openWorksheet={openWorksheet}
            setOpenWorksheet={setOpenWorksheet}
            defaultTabSelected={defaultTabSelected}
            setDefaultTabSelected={setDefaultTabSelected}
          />
        </div>
      </div>
    </FullPageLayout>
  );
}
