import { Alert, Button, Card, Col, Row } from "antd";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";
import SessionFlower from "../SessionFlower";
import { useEffect, useRef, useState } from "react";
import {
  addRoleDescriptor,
  deleteRoleDescriptorById,
  updateIndividualPetalInfoByTeamId,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import {
  clearFlowerDataBySessionID,
  getFlowerDataBySessionID,
} from "../../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../../config/hooks";
import { Id, Role } from "../../../../../../../molecules/KanbanBoard/types";
import RoleCardWithoutSort from "../../../../../../../molecules/KanbanBoard/Components/RoleCardWithoutSort";
import { MenuSelectedIcon } from "../../../../../../CustomIcons";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function FpSessionRole(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    petalTeams,
    sessionData,
    sessionId,
    setLoading,
  } = props;
  const [seriousName, setSeriousName] = useState("");
  const [funnyName, setFunnyName] = useState("");
  const [funnyNameApi, setFunnyNameApi] = useState("");
  const [seriousNameApi, setSeriousNameApi] = useState("");
  const [roleToUpdate, setRoleToUpdate] = useState<any>(undefined);
  const dispatch = useAppDispatch();
  const seriousNameRef = useRef("");
  const funnyNameRef = useRef("");

  const addNames = () => {
    if (funnyName || seriousName) {
      // setLoading(true);
      const payload = {
        funny_name: funnyName,
        serious_name: seriousName,
        petal_id: activeData?.id,
      };

      updateIndividualPetalInfoByTeamId(petalTeams[0].id, payload).then(() => {
        if (funnyName && seriousName) {
          updateApi();
        }
      });
    }
  };

  const submitSFNames = () => {
    if (sessionData?.last_session_screen > 7) {
      setCurrent(8);
    } else {
      setLoading(true);
      const payloadScreen = {
        last_session_screen: 8,
      };
      updateSessionById(payloadScreen, sessionId).then(() => {
        setLoading(false);
        dispatch(clearFlowerDataBySessionID());
        setCurrent(8);
      });
    }
  };

  useEffect(() => {
    if (petalTeams?.length > 0) {
      petalTeams?.map((e: any) => {
        const seriousName = e?.contributions.find(
          (item1: any) => activeData?.id === item1.petal_id
        )?.serious_name;

        const funnyName = e?.contributions.find(
          (item1: any) => activeData?.id === item1.petal_id
        )?.funny_name;

        setSeriousName(seriousName);
        setFunnyName(funnyName);
        setFunnyNameApi(funnyName);
        setSeriousNameApi(seriousName);
      });
    }
  }, [petalTeams]);

  const addMoreDescriptor = () => {
    const payload = {
      session_id: sessionId,
      descriptor: "descriptor",
      index: activeData?.descriptors?.length + 1,
      petal_id: activeData?.id,
    };

    addRoleDescriptor(payload).then(() => {
      updateApi();
    });
  };

  const deleteRole = (id: Id) => {
    deleteRoleDescriptorById(id).then(() => {
      updateApi();
    });
  };

  const updateRole = (id: Id, descriptor: string) => {
    const updatedLocal = activeData?.descriptors.map((role: Role) => {
      if (role.id !== id) {
        return role;
      }
      return { ...role, descriptor };
    });
    const findRoleById = activeData?.descriptors.find(
      (role: { id: string }) => role.id === id
    );
    const updatedRole = { ...findRoleById, descriptor };

    setRoleToUpdate(updatedRole);
    console.log(updatedLocal);
  };

  const updateApi = () => {
    dispatch(getFlowerDataBySessionID(sessionId));
  };

  const handleSeriousNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeriousName(e.target.value);
    seriousNameRef.current = e.target.value; // Update ref
  };

  const handleFunnyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFunnyName(e.target.value);
    funnyNameRef.current = e.target.value; // Update ref
  };

  const handleSeriousNameBlur = () => {
    if (
      seriousNameRef.current &&
      seriousNameRef.current !== seriousNameApi &&
      seriousName
    ) {
      addNames();
    }
  };

  const handleFunnyNameBlur = () => {
    if (
      funnyNameRef.current &&
      funnyNameRef.current !== funnyNameApi &&
      funnyName
    ) {
      addNames();
    }
  };

  const findEmptyRoles = () => {
    if (!petalTeams || petalTeams?.length === 0 || !data?.sessionPetals) {
      // Handle missing data
      return true; // Disable button if data is missing
    }

    const firstTeam = petalTeams?.[0]; // Access the first team directly

    if (firstTeam?.contributions?.length !== data?.sessionPetals?.length) {
      return true; // Disable if contribution lengths don't match
    }

    return firstTeam?.contributions?.some(
      (
        contribution: { serious_name: string; funny_name: string } // Use some() for efficiency
      ) => !contribution?.serious_name || !contribution?.funny_name
    );
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getFlowerDataBySessionID(sessionId));
    }
  }, [sessionId]);

  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            {data?.sessionPetals?.length > 0 && (
              <>
                <SessionFlower
                  data={data?.sessionPetals}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  activeData={activeData}
                  petalTeams={petalTeams}
                />
                <div className="flower_position">
                  <div className="flew_wrapper">
                    <h4>{sessionData?.job?.name}</h4>
                    <p>
                      {sessionData?.c_person?.first_name ?? ""}{" "}
                      {sessionData?.c_person?.last_name ?? ""}{" "}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          {sessionData?.last_session_screen > 7 && (
            <Alert
              message="This activity is completed."
              icon={<MenuSelectedIcon />}
              type="success"
              showIcon
              style={{ marginBottom: 16, border: 0 }}
            />
          )}
          <h4>Come up with role names</h4>
          <p>Brainstorm and enter serious and funny names for each role.</p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              Role
            </div>
            {petalTeams?.map(() => (
              <div className="inputFields">
                <div style={{ width: "100%" }}>
                  <label> Serious name: </label>
                  <input
                    onChange={handleSeriousNameChange}
                    className="ant-input ant-input-outlined formControl"
                    placeholder="Type a serious name here"
                    // defaultValue={
                    //   e?.contributions.find(
                    //     (item1: any) => activeData?.id === item1.petal_id
                    //   )?.serious_name
                    // }
                    value={seriousName}
                    onBlur={handleSeriousNameBlur}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <label> Funny name: </label>
                  <input
                    onChange={handleFunnyNameChange}
                    className="ant-input ant-input-outlined formControl"
                    placeholder="Type a funny name here"
                    // defaultValue={
                    //   e?.contributions.find(
                    //     (item1: any) => activeData?.id === item1.petal_id
                    //   )?.funny_name
                    // }
                    value={funnyName}
                    onBlur={handleFunnyNameBlur}
                  />
                </div>
              </div>
            ))}

            <div className="role_description">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <h4> Role descriptors: </h4>
                <Button onClick={addMoreDescriptor} type="text">
                  + Add More
                </Button>
              </div>
              {activeData?.descriptors?.map((role: Role) => (
                <RoleCardWithoutSort
                  key={role.id}
                  role={role}
                  deleteRole={deleteRole}
                  updateRole={updateRole}
                  roleToUpdate={roleToUpdate}
                  updateApi={updateApi}
                  petalsSession={data?.sessionPetals}
                  oldPetalId={activeData?.id}
                  createRole={addMoreDescriptor}
                />
              ))}
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous role
            </Button>
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={+activeTab === data?.sessionPetals?.length}
              onClick={() => setActiveTab((+activeTab + 1).toString())}
            >
              Next role
            </Button>
            {/* {activeNumber - 1 !== 0 && (
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                disabled={activeTab === "1"}
                onClick={() => {
                  setActiveTab((+activeTab - 1).toString());
                }}
              >
                Role {activeNumber - 1}
              </Button>
            )}
            {activeNumber + 1 <= data?.sessionPetals?.length && (
              <Button
                style={{ marginLeft: "auto" }}
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                disabled={+activeTab === data?.sessionPetals?.length}
                onClick={() => setActiveTab((+activeTab + 1).toString())}
              >
                Role {activeNumber + 1}
              </Button>
            )} */}
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() =>
                  petalTeams?.length > 1 ? setCurrent(6) : setCurrent(5)
                }
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                disabled={findEmptyRoles()}
                onClick={submitSFNames}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
