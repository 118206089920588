import { Fragment, useEffect, useState } from "react";
import { Card, Button, Avatar } from "antd";
import { useLocation } from "react-router-dom";
import FullPageLayout from "../../../../../templates/FullPageLayout";
import { CloseIcon } from "../../../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import {
  clearSessionById,
  getSessionById,
} from "../../../../../organisms/MyJob/ducks/actions";
import { getInitials } from "../../../../../config/constants";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step6 from "./Step6";
import Step5 from "./Step5";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";
import Step11 from "./Step11";
import ConfirmDelete from "../../../../ConfirmDelete";
import { Popup } from "../../../../Popup";
import { updateJobById } from "../../../../../organisms/CompanyDetails/ducks/services";
import ErrorMessage from "../../../../Toasts/ErrorMessage";
import { AxiosError } from "axios";
import StepSelectParticipants from "./StepSelectParticipants";
import SuccessMessage from "../../../../Toasts/SuccessMessage";

export default function SessionSteps() {
  const dispatch = useAppDispatch();
  const url = useLocation();
  const sessionId = url?.pathname.split("/")[2];
  const sessionData = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.data
  );
  const sessionLoader = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.loading
  );

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [confirmEnding, setConfirmEnding] = useState(false);
  const [confirmSuspend, setConfirmSuspend] = useState(false);

  const closeAndEndSession = (type: string) => {
    setLoading(true);
    dispatch(clearSessionById());
    const payload = {
      status: type === "end" ? "pending_fp_session" : "suspended_fp_session",
      company_id: sessionData?.company_id,
    };
    updateJobById(payload, sessionData?.job_id)
      .then(() => {
        if (type === "end") {
          SuccessMessage("Session canceled.");
        } else {
          SuccessMessage("Session suspended.");
        }
        setTimeout(() => {
          setLoading(false);
          window.location.assign(
            `/job-detail/${sessionData?.job_id}/${sessionData?.company_id}`
          );
        }, 1000);
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          if (
            e?.response?.data?.error?.statusCode === 404 ||
            !sessionData?.company_id
          ) {
            window.location.assign(`/`);
          } else {
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
          }
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  const confirmEndingPopup = {
    visibility: confirmEnding,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm cancelation"
        heading="Cancel Flower Power session?"
        paragraph_1="Your progress will be lost and you'll need to start over."
        onClose={() => setConfirmEnding(false)}
        btnText="Cancel session"
        btnApi={() => closeAndEndSession("end")}
        loading={loading}
        secondBtnText="Back to session"
      />
    ),
    width: 520,
    onCancel: () => setConfirmEnding(false),
  };

  const confirmSuspendPopup = {
    visibility: confirmSuspend,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm pause"
        heading="Pause Flower Power session?"
        paragraph_1="Your progress will be saved. Next time, you can resume the session from where you left off."
        onClose={() => setConfirmSuspend(false)}
        btnText="Pause session"
        btnApi={() => closeAndEndSession("suspend")}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmSuspend(false),
  };

  const steps = [
    {
      title: "Select Participants",
      content: (
        <StepSelectParticipants
          setCurrent={setCurrent}
          setLoading={setLoading}
          sessionData={sessionData}
        />
      ),
    },
    {
      title: "Select Center Person",
      content: (
        <Step1
          setCurrent={setCurrent}
          setLoading={setLoading}
          sessionData={sessionData}
        />
      ),
    },
    {
      title: "Link",
      content: (
        <Step2
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
          loading={loading}
        />
      ),
    },
    {
      title: "Petal Create",
      content: (
        <Step3
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Ready to split",
      content: (
        <Step4
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
        />
      ),
    },
    {
      title: "Split the group",
      content: (
        <Step5
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Link Copy for Roles",
      content: (
        <Step6
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
          loading={loading}
        />
      ),
    },
    {
      title: "Insert Serious Funny",
      content: (
        <Step7
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Finalized",
      content: (
        <Step8
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Category",
      content: (
        <Step9
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Statement",
      content: (
        <Step10
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Final",
      content: (
        <Step11
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
        />
      ),
    },
  ];

  useEffect(() => {
    if (sessionData?.last_session_screen) {
      setCurrent(Math.floor(sessionData?.last_session_screen));
    }
  }, [setCurrent, sessionData?.last_session_screen]);

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionById(sessionId));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    dispatch(clearSessionById());
  }, [dispatch]);

  return (
    <FullPageLayout loading={loading || sessionLoader}>
      {steps[current]?.title !== "Final" && (
        <Card className="fp_layout_title session_title">
          <div className="mainTitle">
            <div className="session_head_left">
              <Button
                className="icon_grey_btn"
                icon={<CloseIcon />}
                onClick={() => setConfirmEnding(true)}
              >
                Cancel session
              </Button>
              <h3>Flower Power Session</h3>
              <div className="session_avatar">
                <Avatar.Group
                  className="avatarGroup"
                  size="small"
                  max={{
                    count: 8,
                    style: {
                      color: "#161616",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                      fontSize: "10px",
                      fontWeight: "500",
                    },
                  }}
                >
                  {sessionData?.participants?.map(
                    (e: {
                      id: string;
                      first_name: string;
                      last_name: string;
                      image: { url: string };
                    }) => (
                      <Fragment key={e?.id}>
                        {e?.image?.url ? (
                          <div className="participant_avatar">
                            <Avatar src={e?.image?.url} />
                          </div>
                        ) : (
                          <div className="participant_avatar">
                            <Avatar>
                              {getInitials(e?.first_name, e?.last_name)}
                            </Avatar>
                          </div>
                        )}
                      </Fragment>
                    )
                  )}
                </Avatar.Group>
              </div>
            </div>
            <div className="session_right_head">
              <Button
                className="icon_grey_btn"
                onClick={() => setConfirmSuspend(true)}
              >
                Pause & save
              </Button>
            </div>
          </div>
        </Card>
      )}
      <div
        className={
          steps[current]?.title === "Final"
            ? "final_step centered_content"
            : "centered_content"
        }
      >
        {steps[current].content}
      </div>

      <Popup {...confirmEndingPopup} />
      <Popup {...confirmSuspendPopup} />
    </FullPageLayout>
  );
}
