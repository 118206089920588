import { useEffect, useState } from "react";
import { Button, Drawer, Image, Tabs, TabsProps } from "antd";
import PurposeStatement from "./PurposeStatement";
import Entities from "./Entities";
import KeyResults from "./KeyResults";
import Meetings from "./Meetings";
import Objectives from "./Objectives";
import ProceduresPolicies from "./ProceduresPolicies";
import Skills from "./Skills";
import Teams from "./Teams";
import Technologies from "./Technologies";
import Workflows from "./Workflows";
import ReportsTo from "./ReportsTo";
import MentoredBy from "./MentoredBy";
import CoachedBy from "./CoachedBy";

type PropsType = {
  openWorksheet: boolean;
  setOpenWorksheet: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultTabSelected?: React.Dispatch<React.SetStateAction<string>>;
  defaultTabSelected?: string;
};

const WorksheetGuide = (props: PropsType) => {
  const {
    openWorksheet,
    setOpenWorksheet,
    defaultTabSelected,
    setDefaultTabSelected,
  } = props;
  const [activeKey, setAvtiveKey] = useState("objectives");
  const showDrawer = () => {
    setOpenWorksheet(true);
  };

  const onClose = () => {
    setOpenWorksheet(false);
    setDefaultTabSelected && setDefaultTabSelected("");
  };

  // useEffect(() => {
  //   if (openWorksheet) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }

  //   return () => {
  //     document.body.style.overflow = "unset";
  //   };
  // }, [openWorksheet]);

  const items: TabsProps["items"] = [
    {
      key: "objectives",
      label: "Objectives",
      children: <Objectives />,
    },
    {
      key: "key_results",
      label: "Key Results",
      children: <KeyResults />,
    },
    {
      key: "procedures_wis_policies",
      label: "Procedures/WIs/Policies",
      children: <ProceduresPolicies />,
    },
    {
      key: "workflows",
      label: "Workflows",
      children: <Workflows />,
    },
    {
      key: "skills",
      label: "Skills",
      children: <Skills />,
    },
    {
      key: "technologies",
      label: "Technologies",
      children: <Technologies />,
    },
    {
      key: "teams",
      label: "Teams",
      children: <Teams />,
    },
    {
      key: "entities",
      label: "Entities",
      children: <Entities />,
    },
    {
      key: "meetings",
      label: "Meetings",
      children: <Meetings />,
    },
    {
      key: "purpose_statement",
      label: "Purpose Statement",
      children: <PurposeStatement />,
    },
    {
      key: "reports__to",
      label: "Reports To",
      children: <ReportsTo />,
    },
    {
      key: "mentored__by",
      label: "Mentored By",
      children: <MentoredBy />,
    },
    {
      key: "coached__by",
      label: "Coached By",
      children: <CoachedBy />,
    },
  ];

  const onChange = (key: string) => {
    setAvtiveKey(key);
  };

  useEffect(() => {
    if (defaultTabSelected) {
      setAvtiveKey(defaultTabSelected);
    }
  }, [defaultTabSelected]);

  return (
    <>
      <Button type="primary" onClick={showDrawer} className="drawerBtn">
        <Image src="/images/guide-icon.svg" preview={false} />
      </Button>

      <Drawer
        title="Worksheet Guide"
        placement="right"
        closable={true}
        onClose={onClose}
        open={openWorksheet}
        className="guide_drawer"
        maskClosable={false}
        mask={false}
      >
        <Tabs
          tabPosition="left"
          destroyInactiveTabPane
          onChange={onChange}
          activeKey={activeKey}
          items={items}
          className="drawer_tabs"
        />
      </Drawer>
    </>
  );
};

export default WorksheetGuide;
