import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Button,
  Select,
  Image,
  Avatar,
  Tag,
  Dropdown,
  Breadcrumb,
  Badge,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTemplate from "../../../templates/Dashboard";
import {
  ArrowIconRight,
  CloseIcon,
  DropdownIcon,
  MenuSelectedIcon,
  SortIcon,
} from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../../config/hooks";
import { Popup } from "../../../atoms/Popup";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { getRolesFilters, getRolesListing } from "../ducks/actions";
import { getInitials } from "../../../config/constants";
import { deleteRole } from "../ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import Header from "../Components/Header";
import CompanyLinks from "../Components/CompanyLinks";

interface DataType {
  key: React.Key;
  id: string;
  name: string;
  jobs: string[];
  coaches: { id: string; name: string };
  holders: string;
}

export default function Roles() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [jobFilter, setJobFilter] = useState<undefined | string[]>(undefined);
  const [coachedByFilter, setCoachedByFilter] = useState<undefined | string[]>(
    undefined
  );
  const [holderFilter, setHolderFilter] = useState<undefined | string[]>(
    undefined
  );
  const [tableRecord, setTableRecord] = useState<DataType>();
  const url = useLocation();
  const companyId = url?.pathname.split("/")[3];
  const urlType = url?.pathname.split("/")[2];
  const rolesFiltersOptions = useAppSelector(
    (state) => state?.companyDetails?.rolesFiltersOptions
  );
  const rolesListData = useAppSelector(
    (state) => state?.companyDetails?.rolesList
  );
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/edit-role/${tableRecord?.id}/${companyId}`);
          }}
        >
          Edit role
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete role
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Role name",
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Jobs",
      dataIndex: "jobs",
      // sorter: (a, b) => a?.jobs?.localeCompare(b?.jobs),
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0
          ? text?.map((e: { id: string; name: string }) => (
              <Tag
                className="defaultTag white_tag"
                style={{ marginRight: "2px", marginLeft: "2px" }}
                key={e?.id}
              >
                {e?.name}
              </Tag>
            ))
          : "-",
    },
    {
      title: "Coached by",
      dataIndex: "coaches",
      sortIcon: () => <SortIcon />,
      render: (text) => (text?.name ? text?.name : "-"),
    },
    {
      title: "Holders",
      dataIndex: "jobs",
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0 && (
          <Avatar.Group
            className="avatarGroup"
            size="small"
            max={{
              count: 2,
              style: {
                color: "#161616",
                backgroundColor: "#fff",
                cursor: "pointer",
                fontSize: "10px",
                fontWeight: "500",
              },
            }}
          >
            {text?.map(
              (e: {
                employees: {
                  id: string;
                  first_name: string;
                  last_name: string;
                  image: { url: string };
                }[];
              }) =>
                e?.employees?.length > 0 &&
                e?.employees?.map(
                  (emp: {
                    id: string;
                    first_name: string;
                    last_name: string;
                    image: { url: string };
                  }) => (
                    <Fragment key={emp?.id}>
                      {emp?.image ? (
                        <Avatar src={emp?.image?.url} />
                      ) : (
                        <Avatar>
                          {getInitials(emp?.first_name, emp?.last_name)}
                        </Avatar>
                      )}
                    </Fragment>
                  )
                )
            )}
          </Avatar.Group>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 160,
      render: (text) => {
        let className = "";
        if (text === "COMPLETED") {
          className = "green_tag";
        } else if (text === "NOT_COMPLETED") {
          className = "grey_tag";
        } else {
          className = "defaultTag";
        }
        return text ? (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge green_badge`} />{" "}
            {text === "NOT_COMPLETED" ? "Not completed" : "Completed"}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setTableRecord(record);
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const individualColumns: TableColumnsType<DataType> = [
    {
      title: "Role name",
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Jobs",
      dataIndex: "jobs",
      // sorter: (a, b) => a?.jobs?.localeCompare(b?.jobs),
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0
          ? text?.map((e: { id: string; name: string }) => (
              <Tag
                className="defaultTag white_tag"
                style={{ marginRight: "2px", marginLeft: "2px" }}
                key={e?.id}
              >
                {e?.name}
              </Tag>
            ))
          : "-",
    },
    {
      title: "Coached by",
      dataIndex: "coaches",
      sortIcon: () => <SortIcon />,
      render: (text) => (text?.name ? text?.name : "-"),
    },
    {
      title: "Holders",
      dataIndex: "jobs",
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0 && (
          <Avatar.Group
            className="avatarGroup"
            size="small"
            max={{
              count: 2,
              style: {
                color: "#161616",
                backgroundColor: "#fff",
                cursor: "pointer",
                fontSize: "10px",
                fontWeight: "500",
              },
            }}
          >
            {text?.map(
              (e: {
                employees: {
                  id: string;
                  first_name: string;
                  last_name: string;
                  image: { url: string };
                }[];
              }) =>
                e?.employees?.length > 0 &&
                e?.employees?.map(
                  (emp: {
                    id: string;
                    first_name: string;
                    last_name: string;
                    image: { url: string };
                  }) => (
                    <Fragment key={emp?.id}>
                      {emp?.image ? (
                        <Avatar src={emp?.image?.url} />
                      ) : (
                        <Avatar>
                          {getInitials(emp?.first_name, emp?.last_name)}
                        </Avatar>
                      )}
                    </Fragment>
                  )
                )
            )}
          </Avatar.Group>
        ),
    },
  ];

  const handleDeleteJob = async () => {
    setDeleteLoading(true);
    deleteRole(tableRecord?.id as string)
      .then(() => {
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          companyId: companyId,
        };
        dispatch(getRolesListing(payload));
        setIsDeleteModalOpen(false);
        setDeleteLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading="Are you sure you want to delete this role?"
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete role"
        btnApi={handleDeleteJob}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    if (companyId && (jobFilter || coachedByFilter || holderFilter)) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
        coachBy:
          coachedByFilter && coachedByFilter.length > 0
            ? coachedByFilter
            : undefined,
        job: jobFilter && jobFilter.length > 0 ? jobFilter : undefined,
        employees:
          holderFilter && holderFilter.length > 0 ? holderFilter : undefined,
      };
      dispatch(getRolesListing(payload));
    } else if (companyId) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
      };
      dispatch(getRolesListing(payload));
    }
  }, [
    pagination,
    limit,
    dispatch,
    companyId,
    jobFilter,
    coachedByFilter,
    holderFilter,
  ]);

  useEffect(() => {
    if (companyId) {
      dispatch(getRolesFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  const onClickRow = (record: DataType) => {
    return {
      onClick: () => {
        navigate(`/edit-role/${record?.id}/${companyId}`);
      },
    };
  };

  const filtersSelected = (type: string, value: string[]) => {
    if (type === "job") {
      setJobFilter(value);
    }
    if (type === "coached_by") {
      setCoachedByFilter(value);
    }
    if (type === "holder") {
      setHolderFilter(value);
    }
  };

  const resetFilters = () => {
    setJobFilter(undefined);
    setCoachedByFilter(undefined);
    setHolderFilter(undefined);
  };

  return (
    <DashboardTemplate loading={rolesListData?.loading}>
      {accountData?.type === "SUPER_ADMIN" && (
        <Row className="secondary_header">
          <Col span={24}>
            <Breadcrumb
              separator={<ArrowIconRight />}
              // style={{ cursor: "pointer" }}
            >
              <Breadcrumb.Item onClick={() => navigate("/companies")}>
                <Image
                  src="/images/breadcrumb_rightarrow.svg"
                  preview={false}
                  width={16}
                  height={16}
                  style={{ marginRight: "14px" }}
                />
                Companies
              </Breadcrumb.Item>
              <Breadcrumb.Item>{companyData?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header companyId={companyId} />
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <CompanyLinks companyId={companyId} urlType={urlType} />
                <div className="filter_right">
                  <div
                    className="filter_btn"
                    style={{ display: "flex", gap: "6px" }}
                  >
                    <span className="filterTitle"> Filter: </span>

                    <Select
                      placeholder="Job"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount={0}
                      options={rolesFiltersOptions?.job}
                      onChange={(e) => filtersSelected("job", e)}
                      value={jobFilter}
                      allowClear={{
                        clearIcon: <CloseIcon />,
                      }}
                      mode="multiple"
                      style={{ minWidth: "75px" }}
                      menuItemSelectedIcon={<MenuSelectedIcon />}
                    />
                    <Select
                      placeholder="Coached by"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount={0}
                      options={rolesFiltersOptions?.coach}
                      onChange={(e) => filtersSelected("coached_by", e)}
                      value={coachedByFilter}
                      allowClear={{
                        clearIcon: <CloseIcon />,
                      }}
                      mode="multiple"
                      style={{ minWidth: "130px" }}
                      menuItemSelectedIcon={<MenuSelectedIcon />}
                    />
                    <Select
                      placeholder="Holder"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount={0}
                      options={rolesFiltersOptions?.employee}
                      onChange={(e) => filtersSelected("holder", e)}
                      allowClear={{
                        clearIcon: <CloseIcon />,
                      }}
                      value={holderFilter}
                      mode="multiple"
                      style={{ minWidth: "95px" }}
                      menuItemSelectedIcon={<MenuSelectedIcon />}
                    />
                    <Button type="text" onClick={resetFilters}>
                      <Image src="/images/filter_btn.svg" preview={false} />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {rolesListData?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={
                Object.keys(accountData)?.length > 0 &&
                accountData?.type === "INDIVIDUAL_CONTRIBUTOR"
                  ? individualColumns
                  : columns
              }
              dataSource={rolesListData?.data}
              pagination={{
                defaultPageSize: limit,
                total: rolesListData?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
              onRow={
                Object.keys(accountData)?.length > 0 &&
                accountData?.type !== "INDIVIDUAL_CONTRIBUTOR"
                  ? onClickRow
                  : undefined
              }
            />
          )}

          {!holderFilter &&
            !coachedByFilter &&
            !jobFilter &&
            rolesListData?.data?.length === 0 && (
              <EmptyCard
                image="/images/empty/no_roles.svg"
                title="There are no roles"
                description=""
                buttonText="Create roles"
                navigateTo={`/create-role/${companyId}`}
              />
            )}

          {((holderFilter && holderFilter?.length > 0) ||
            (coachedByFilter && coachedByFilter?.length > 0) ||
            (jobFilter && jobFilter?.length > 0)) &&
            rolesListData?.data?.length === 0 && (
              <Col span={24} className="first_job_card">
                <img
                  src="/images/no-result-found.svg"
                  alt=""
                  className="first_job_img"
                  style={{ marginBottom: "24px" }}
                />
                <h4> There’s nothing matching the filter criteria. </h4>
              </Col>
            )}
        </Col>
      </Row>
      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
