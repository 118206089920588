export const JOBS_LIST = "JOBS_LIST";
export const ROLES_LIST = "ROLES_LIST";
export const EMPLOYEES_LIST = "EMPLOYEES_LIST";
export const JOB_FILTERS = "JOB_FILTERS";
export const ROLES_FILTERS = "ROLES_FILTERS";
export const COMPANY_DROP = "COMPANY_DROP";
export const ROLE_DROP = "ROLE_DROP";
export const EMPLOYEES_FILTERS = "EMPLOYEES_FILTERS";
export const ROLES_DETAILS = "ROLES_DETAILS";
export const SET_ROLE_NAMES = "SET_ROLE_NAMES";
export const SET_ROLE_NAMES_API = "SET_ROLE_NAMES_API";
export const SESSION_DETAILS = "SESSION_DETAILS";
