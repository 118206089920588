import Lottie from "react-lottie";
import { Card, Button, Row, Col, Alert, Input } from "antd";
import { SessionTypeResponse } from "../../../../../../organisms/MyJob/ducks/types";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";
import { getSessionById } from "../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../config/hooks";
import SuccessMessage from "../../../../../Toasts/SuccessMessage";
import {
  BASE_URLS,
  ENVIRONMENT_MODE,
} from "../../../../../../config/constants";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../Toasts/ErrorMessage";
import * as animationData from "../Components/LottieAnimations/FP_Name_animation.json";
import { useEffect, useState } from "react";
import {
  MenuSelectedIcon,
  SubmitIcon,
  WaitingIcon,
  WritingIcon,
} from "../../../../../CustomIcons";
import { Popup } from "../../../../../Popup";
import ConfirmProceeding from "../../../../../ConfirmProceeding";

const webUrl = BASE_URLS[ENVIRONMENT_MODE].REACT_WEB_URL;

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step2(props: PropsType) {
  const { setCurrent, sessionData, sessionId, setLoading, loading } = props;
  const [confirmProceeding, setConfirmProceeding] = useState(false);
  const [participantStatus, setParticipantStatus] = useState<null | string>(
    "NOT_STARTED"
  );

  const dispatch = useAppDispatch();
  const generateListLink = `${webUrl}/fp-session/generate-list/${sessionId}`;

  const updateLastScreen = () => {
    setLoading(true);
    const payload = {
      last_session_screen: 3,
    };
    updateSessionById(payload, sessionId)
      .then(() => {
        dispatch(getSessionById(sessionId));
        setCurrent(3);
        setLoading(false);
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  const checkCurrentStatus = () => {
    if (sessionData?.last_session_screen > 2) {
      setCurrent(3);
    } else if (
      (participantStatus === "NOT_STARTED" ||
        participantStatus === "PENDING") &&
      sessionData?.last_session_screen === 2
    ) {
      setConfirmProceeding(true);
    } else {
      updateLastScreen();
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const copyLink = () => {
    navigator.clipboard.writeText(generateListLink);
    SuccessMessage("Link copied");
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionById(sessionId));
    }
  }, [sessionId]);

  useEffect(() => {
    if (!sessionData?.participants || sessionData?.participants?.length === 0) {
      setParticipantStatus(null); // Handle empty or null data
      return;
    }

    const hasPending = sessionData?.participants?.some(
      (item) => item?.participant_status === "PENDING"
    );
    const allSubmitted = sessionData?.participants?.every(
      (item) => item?.participant_status === "SUBMITTED"
    );

    if (hasPending) {
      setParticipantStatus("PENDING");
    } else if (allSubmitted) {
      setParticipantStatus("SUBMITTED");
    }
  }, [sessionData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sessionId) {
        dispatch(getSessionById(sessionId));
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [dispatch, sessionId]);

  const confirmProceed = {
    visibility: confirmProceeding,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmProceeding
        title="Lists are not completed"
        onClose={() => setConfirmProceeding(false)}
        paragraph="Some participants haven't finished their lists and won't be able to
            submit them if you proceed. Are you sure you want to continue?"
        btnText="Yes, continue anyway"
        btnApi={() => updateLastScreen()}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmProceeding(false),
  };
  return (
    <Row className="shareLink">
      <Col span={12} className="col_1">
        <Card className="fp_layout_content link_card copy_card">
          <div className="copy_link_content">
            {sessionData?.last_session_screen > 2 && (
              <Alert
                message="This activity is completed."
                icon={<MenuSelectedIcon />}
                type="success"
                showIcon
                style={{ marginBottom: 16, border: 0 }}
                className="success_copy"
              />
            )}
            <div className="session2_content">
              <h2>Generate the list</h2>
              <p style={{ marginBottom: "10px" }}>
                Share the link below with each participant.
              </p>
              <p>
                Ask them to take 2 to 3 quiet minutes to create a list. Ask them
                to write down everything that{" "}
                <b>
                  {`${sessionData?.c_person?.first_name ?? ""} ${
                    sessionData?.c_person?.last_name ?? ""
                  }`}{" "}
                </b>
                is thinking about or doing, or should be thinking about or doing
                every second, minute, hour, day, week, month, year to fulfill
                the objective of their Job.
              </p>
              <div
                className="copyLinkText"
                style={{ position: "relative", marginTop: 24 }}
              >
                <Input
                  value={generateListLink}
                  placeholder={generateListLink}
                />
                <Button
                  className="copyLinkBtn"
                  onClick={copyLink}
                  disabled={sessionData?.last_session_screen > 2}
                >
                  <img src="/images/attachment_icon.svg" alt="" /> Copy link
                </Button>
              </div>

              {participantStatus === "NOT_STARTED" &&
                sessionData?.last_session_screen === 2 && (
                  <div className="info_alert">
                    <Alert
                      message="Status: Participants are waiting for the link"
                      description="Once the link is shared, participants will start writing."
                      icon={<WaitingIcon />}
                      type="info"
                      showIcon
                    />
                  </div>
                )}

              {participantStatus === "PENDING" &&
                sessionData?.last_session_screen === 2 && (
                  <div className="warning_alert">
                    <Alert
                      message="Status: Participants are writing their lists ..."
                      description="Wait for everyone to submit their lists. This status will turn green when all lists are submitted."
                      icon={<WritingIcon />}
                      type="warning"
                      showIcon
                    />
                  </div>
                )}

              {participantStatus === "SUBMITTED" &&
                sessionData?.last_session_screen === 2 && (
                  <div className="submitted_icon">
                    <Alert
                      message="Status: All lists submitted"
                      description={`Everyone's lists are in! Click "Continue" to proceed.`}
                      icon={<SubmitIcon />}
                      type="success"
                      showIcon
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(1)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={checkCurrentStatus}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={12} className="col_2">
        <div className="step2_role">
          <Lottie options={defaultOptions} width={510} />
        </div>
      </Col>
      <Popup {...confirmProceed} />
    </Row>
  );
}
