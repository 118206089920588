import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Button,
  Select,
  Image,
  Avatar,
  Tag,
  Badge,
  Dropdown,
  Breadcrumb,
  Tooltip,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTemplate from "../../../templates/Dashboard";
import {
  ArrowIconRight,
  CloseIcon,
  DropdownIcon,
  MenuSelectedIcon,
  SortIcon,
} from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../../config/hooks";
import { Popup } from "../../../atoms/Popup";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { getUsersListing, getUserFilters } from "../ducks/actions";
import {
  getInitials,
  USER_STATUS_TYPE,
  USER_TYPE,
} from "../../../config/constants";
import Header from "../Components/Header";
import CompanyLinks from "../Components/CompanyLinks";
import { AxiosError } from "axios";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import { deleteUserById, updateEmployeeById } from "../ducks/services";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";

interface DataType {
  key: React.Key;
  employee_name: { text: string; image: string };
  job: { name: string; roles: { name: string }[] };
  roles: string[];
  type: string;
  id: string;
  status: string;
  first_name: string;
  last_name: string;
  image: { id: string; name: string; url: string };
}

export default function Employees() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeActiveModalOpen, setIsDeActiveModalOpen] = useState(false);
  const [jobFilter, setJobFilter] = useState<undefined | string[]>(undefined);
  const [roleFilter, setRoleFilter] = useState<undefined | string[]>(undefined);
  const [typeFilter, setTypeFilter] = useState<undefined | string[]>(undefined);
  const [statusFilter, setStatusFilter] = useState<undefined | string[]>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [TypeDropdown, setTypeDropdown] = useState<
    { label: string; value: string }[]
  >([]);
  const [statusDropdown, setStatusDropdown] = useState<
    { label: string; value: string }[]
  >([]);
  const [tableRecord, setTableRecord] = useState<DataType>();
  const url = useLocation();
  const companyId = url?.pathname.split("/")[3];
  const urlType = url?.pathname.split("/")[2];
  const employeeFiltersOptions = useAppSelector(
    (state) => state?.companyDetails?.employeeFiltersOptions
  );
  const employeesListData = useAppSelector(
    (state) => state?.companyDetails?.employeesList
  );
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/edit-employee/${tableRecord?.id}/${companyId}`);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      key: "2",
      disabled: tableRecord?.id === accountData?.id,
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          disabled={tableRecord?.id === accountData?.id}
          onClick={(e) => {
            e.stopPropagation();
            tableRecord?.status === "active"
              ? setIsDeActiveModalOpen(true)
              : handleActiveUser();
          }}
        >
          {tableRecord?.status === "active" ? "Deactivate" : "Activate"}
        </Button>
      ),
    },
    {
      key: "3",
      disabled: tableRecord?.id === accountData?.id,
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
          disabled={tableRecord?.id === accountData?.id}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Employee name",
      dataIndex: "first_name",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <div className="table_with_image">
          {record?.image ? (
            <Image
              src={record?.image?.url}
              width={28}
              height={28}
              alt="Name"
              preview={false}
              style={{ borderRadius: "100px" }}
            />
          ) : (
            <Avatar>{getInitials(text, record?.last_name)}</Avatar>
          )}
          <div className="table_text">
            <span className="main_text">{`${text ?? ""} ${
              record?.last_name ?? ""
            }`}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Job",
      dataIndex: "job",
      sortIcon: () => <SortIcon />,
      render: (text) => (text?.name ? text?.name : "-"),
    },
    {
      title: "Roles",
      dataIndex: "job",
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <div className="location_table_wrapper">
          {text && text?.roles?.length > 0 ? (
            <>
              {text?.roles?.length > 3 ? (
                <>
                  <div className="list_view">
                    {text?.roles?.map(
                      (e: any, index: number) =>
                        index < 3 && (
                          <Tag
                            className="defaultTag white_tag"
                            style={{ marginRight: "2px", marginLeft: "2px" }}
                            key={e?.id}
                          >
                            {e?.name}
                          </Tag>
                        )
                    )}
                  </div>
                  <div className="tooltip_location">
                    <Tooltip
                      title={
                        <>
                          {text?.roles?.map(
                            (e: any, index: number) =>
                              index > 2 && (
                                <div key={e?.id} style={{ marginTop: 5 }}>
                                  {e?.name}
                                  <br />
                                </div>
                              )
                          )}
                        </>
                      }
                    >
                      + {text?.roles?.length - 3}
                    </Tooltip>
                  </div>
                </>
              ) : (
                text?.roles?.map((e: { name: string; id: string }) => (
                  <Tag
                    className="defaultTag white_tag"
                    style={{ marginRight: "2px", marginLeft: "2px" }}
                    key={e?.id}
                  >
                    {e?.name}
                  </Tag>
                ))
              )}
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "User type",
      dataIndex: "type",
      render: (text: keyof typeof USER_TYPE) => (text ? USER_TYPE[text] : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => {
        let className = "";
        if (text === "active") {
          className = "green_tag";
        } else if (text === "inactive") {
          className = "grey_tag";
        } else {
          className = "defaultTag";
        }
        return text ? (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge green_badge`} />{" "}
            {text === "inactive" ? "Deactivated" : "Active"}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: (_text, record) => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setTableRecord(record);
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const individualColumns: TableColumnsType<DataType> = [
    {
      title: "Employee name",
      dataIndex: "first_name",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <div className="table_with_image">
          {record?.image ? (
            <Image
              src={record?.image?.url}
              width={28}
              height={28}
              alt="Name"
              preview={false}
              style={{ borderRadius: "100px" }}
            />
          ) : (
            <Avatar>{getInitials(text, record?.last_name)}</Avatar>
          )}
          <div className="table_text">
            <span className="main_text">{`${text ?? ""} ${
              record?.last_name ?? ""
            }`}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Job",
      dataIndex: "job",
      sortIcon: () => <SortIcon />,
      render: (text) => (text?.name ? text?.name : "-"),
    },
    {
      title: "Roles",
      dataIndex: "job",
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <div className="location_table_wrapper">
          {text && text?.roles?.length > 0 ? (
            <>
              {text?.roles?.length > 3 ? (
                <>
                  <div className="list_view">
                    {text?.roles?.map(
                      (e: any, index: number) =>
                        index < 3 && (
                          <Tag
                            className="defaultTag white_tag"
                            style={{ marginRight: "2px", marginLeft: "2px" }}
                            key={e?.id}
                          >
                            {e?.name}
                          </Tag>
                        )
                    )}
                  </div>
                  <div className="tooltip_location">
                    <Tooltip
                      title={
                        <>
                          {text?.roles?.map(
                            (e: any, index: number) =>
                              index > 2 && (
                                <div key={e?.id} style={{ marginTop: 5 }}>
                                  {e?.name}
                                  <br />
                                </div>
                              )
                          )}
                        </>
                      }
                    >
                      + {text?.roles?.length - 3}
                    </Tooltip>
                  </div>
                </>
              ) : (
                text?.roles?.map((e: { name: string; id: string }) => (
                  <Tag
                    className="defaultTag white_tag"
                    style={{ marginRight: "2px", marginLeft: "2px" }}
                    key={e?.id}
                  >
                    {e?.name}
                  </Tag>
                ))
              )}
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "User type",
      dataIndex: "type",
      render: (text: keyof typeof USER_TYPE) => (text ? USER_TYPE[text] : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => {
        let className = "";
        if (text === "active") {
          className = "green_tag";
        } else if (text === "inactive") {
          className = "grey_tag";
        } else {
          className = "defaultTag";
        }
        return text ? (
          <Tag className={`defaultTag ${className}`}>
            <Badge className={`default_badge green_badge`} />{" "}
            {text === "inactive" ? "Deactivated" : "Active"}
          </Tag>
        ) : (
          "-"
        );
      },
    },
  ];

  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    deleteUserById(tableRecord?.id as string)
      .then(() => {
        setDeleteLoading(false);
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          companyId: companyId,
        };
        dispatch(getUsersListing(payload));
        setTimeout(() => {
          setIsDeleteModalOpen(false);
        }, 200);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const handleDeActiveUser = async () => {
    setDeleteLoading(true);

    const payload = {
      status: "inactive",
    };
    updateEmployeeById(payload, tableRecord?.id as string)
      .then(() => {
        SuccessMessage("User deactivated");
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          companyId: companyId,
        };
        dispatch(getUsersListing(payload));
        setDeleteLoading(false);
        setIsDeActiveModalOpen(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setDeleteLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setDeleteLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const handleActiveUser = async () => {
    setLoading(true);

    const payload = {
      status: "active",
    };
    updateEmployeeById(payload, tableRecord?.id as string)
      .then(() => {
        setLoading(false);
        SuccessMessage("User activated");
        const payload = {
          offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
          limit: limit,
          companyId: companyId,
        };
        dispatch(getUsersListing(payload));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading={`Delete ${tableRecord?.first_name ?? ""} ${
          tableRecord?.last_name ?? ""
        }?`}
        paragraph_1="This user will be deleted. This action can't be undone."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete user"
        btnApi={handleDeleteUser}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  const deActiveConfirmationPopup = {
    visibility: isDeActiveModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deactivation"
        heading={`Deactivate ${tableRecord?.first_name ?? ""} ${
          tableRecord?.last_name ?? ""
        } user?`}
        paragraph_1="The user will be archived and will lose access to the system."
        onClose={() => setIsDeActiveModalOpen(false)}
        btnText="Deactivate user"
        btnApi={handleDeActiveUser}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeActiveModalOpen(false),
  };

  useEffect(() => {
    if (jobFilter || roleFilter || typeFilter || statusFilter) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
        status: statusFilter ? statusFilter : undefined,
        roles: roleFilter && roleFilter.length > 0 ? roleFilter : undefined,
        job: jobFilter && jobFilter.length > 0 ? jobFilter : undefined,
        userType: typeFilter ? typeFilter : undefined,
      };
      dispatch(getUsersListing(payload));
    } else if (companyId) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: companyId,
      };
      dispatch(getUsersListing(payload));
    }
  }, [
    roleFilter,
    jobFilter,
    typeFilter,
    statusFilter,
    dispatch,
    pagination,
    limit,
    companyId,
  ]);

  useEffect(() => {
    if (companyId) {
      dispatch(getUserFilters(companyId));
    }
  }, [dispatch, companyId]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  useEffect(() => {
    if (employeeFiltersOptions?.type) {
      const typeArray: { label: string; value: string }[] = [];
      employeeFiltersOptions?.type?.map(
        (e: { label: keyof typeof USER_TYPE; value: string }) => {
          typeArray.push({
            label: USER_TYPE[e?.label],
            value: e?.value,
          });
        }
      );
      setTypeDropdown(typeArray);
    }
  }, [employeeFiltersOptions?.type]);

  useEffect(() => {
    if (employeeFiltersOptions?.status) {
      const typeArray: { label: string; value: string }[] = [];
      employeeFiltersOptions?.status?.map(
        (e: { label: keyof typeof USER_STATUS_TYPE; value: string }) => {
          typeArray.push({
            label: USER_STATUS_TYPE[e?.label],
            value: e?.value,
          });
        }
      );
      setStatusDropdown(typeArray);
    }
  }, [employeeFiltersOptions?.status]);

  const onClickRow = (record: DataType) => {
    return {
      onClick: () => {
        navigate(`/employee-details/${record?.id}/${companyId}`);
      },
    };
  };

  const filtersSelected = (type: string, value: string[]) => {
    if (type === "job") {
      setJobFilter(value);
    }
    if (type === "roles") {
      setRoleFilter(value);
    }
    if (type === "user_type") {
      setTypeFilter(value);
    }
    if (type === "status") {
      setStatusFilter(value);
    }
  };

  const resetFilters = () => {
    setJobFilter(undefined);
    setRoleFilter(undefined);
    setTypeFilter(undefined);
    setStatusFilter(undefined);
  };

  console.log(
    "role",
    roleFilter,
    jobFilter,
    typeFilter,
    statusFilter,
    employeesListData
  );

  return (
    <DashboardTemplate loading={employeesListData?.loading || loading}>
      {accountData?.type === "SUPER_ADMIN" && (
        <Row className="secondary_header">
          <Col span={24}>
            <Breadcrumb
              separator={<ArrowIconRight />}
              // style={{ cursor: "pointer" }}
            >
              <Breadcrumb.Item onClick={() => navigate("/companies")}>
                <Image
                  src="/images/breadcrumb_rightarrow.svg"
                  preview={false}
                  width={16}
                  height={16}
                  style={{ marginRight: "14px" }}
                />
                Companies
              </Breadcrumb.Item>
              <Breadcrumb.Item>{companyData?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header companyId={companyId} />
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <CompanyLinks companyId={companyId} urlType={urlType} />
                <div className="filter_right">
                  <div
                    className="filter_btn"
                    style={{ display: "flex", gap: "6px" }}
                  >
                    <span className="filterTitle"> Filter: </span>

                    <Select
                      placeholder="Job"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount={0}
                      options={employeeFiltersOptions?.job}
                      onChange={(e) => filtersSelected("job", e)}
                      value={jobFilter}
                      allowClear={{
                        clearIcon: <CloseIcon />,
                      }}
                      mode="multiple"
                      style={{ minWidth: "70px" }}
                      menuItemSelectedIcon={<MenuSelectedIcon />}
                    />
                    <Select
                      placeholder="Roles"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount={0}
                      options={employeeFiltersOptions?.role}
                      onChange={(e) => filtersSelected("roles", e)}
                      value={roleFilter}
                      allowClear={{
                        clearIcon: <CloseIcon />,
                      }}
                      mode="multiple"
                      style={{ minWidth: "80px" }}
                      menuItemSelectedIcon={<MenuSelectedIcon />}
                    />
                    <Select
                      placeholder="User type"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount={0}
                      options={TypeDropdown}
                      onChange={(e) => filtersSelected("user_type", e)}
                      value={typeFilter}
                      allowClear={{
                        clearIcon: <CloseIcon />,
                      }}
                      style={{ minWidth: "110px" }}
                      menuItemSelectedIcon={<MenuSelectedIcon />}
                    />
                    <Select
                      placeholder="Status"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount={0}
                      options={statusDropdown}
                      onChange={(e) => filtersSelected("status", e)}
                      value={statusFilter}
                      allowClear={{
                        clearIcon: <CloseIcon />,
                      }}
                      style={{ minWidth: "90px" }}
                      menuItemSelectedIcon={<MenuSelectedIcon />}
                    />
                    <Button type="text" onClick={resetFilters}>
                      <Image src="/images/filter_btn.svg" preview={false} />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {employeesListData?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={
                Object.keys(accountData)?.length > 0 &&
                accountData?.type === "INDIVIDUAL_CONTRIBUTOR"
                  ? individualColumns
                  : columns
              }
              dataSource={employeesListData?.data}
              pagination={{
                defaultPageSize: limit,
                total: employeesListData?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
              onRow={
                Object.keys(accountData)?.length > 0 &&
                accountData?.type !== "INDIVIDUAL_CONTRIBUTOR"
                  ? onClickRow
                  : undefined
              }
            />
          )}

          {!jobFilter &&
            !roleFilter &&
            !typeFilter &&
            !statusFilter &&
            employeesListData?.data?.length === 0 && (
              <EmptyCard
                image="/images/empty/no_employees.svg"
                title="There are no employees"
                description=""
                buttonText="Create employee"
                navigateTo={`/add-employees/${companyId}`}
              />
            )}

          {((jobFilter && jobFilter?.length > 0) ||
            (roleFilter && roleFilter?.length > 0) ||
            (typeFilter && typeFilter?.length > 0) ||
            (statusFilter && statusFilter?.length > 0)) &&
            employeesListData?.data?.length === 0 && (
              <Col span={24} className="first_job_card">
                <img
                  src="/images/no-result-found.svg"
                  alt=""
                  className="first_job_img"
                  style={{ marginBottom: "24px" }}
                />
                <h4> There’s nothing matching the filter criteria. </h4>
              </Col>
            )}
        </Col>
      </Row>

      <Popup {...deleteConfirmationPopup} />
      <Popup {...deActiveConfirmationPopup} />
    </DashboardTemplate>
  );
}
