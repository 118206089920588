import { useEffect, useState } from "react";
import { Row, Col, Card, Tag, Breadcrumb, Image, Badge, Alert } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DetailTop from "./Components/DetailTop";
import JobSummary from "./Components/JobSummary";
import JobRoles from "./Components/JobRoles";
import { JobDetailType } from "../../organisms/MyJob/ducks/types";
import EmptySessionCard from "../../molecules/EmptySessionCard";
import { Popup } from "../Popup";
import SelectParticipants from "./Components/SelectParticipants";
import { ArrowIconRight } from "../CustomIcons";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getSessionById } from "../../organisms/MyJob/ducks/actions";
import { STATUS_TYPE } from "../../config/constants";
import EmptySessionCardSessionStart from "../../molecules/EmptySessionCardSessionStart";

type PropsType = {
  jobDetailData: JobDetailType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function JobDetails(props: PropsType) {
  const { jobDetailData, setLoading } = props;
  const [selectWSParticipant, showSelectWSParticipant] = useState(false);
  const url = useLocation();
  const dispatch = useAppDispatch();
  const jobUrl = url?.pathname.split("/")[1];
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const sessionData = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.data
  );
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const navigate = useNavigate();

  // const participantPopUp = {
  //   visibility: selectParticipant,
  //   class: "delete_confirmation_modal",
  //   content: (
  //     <SelectParticipants
  //       title="Start Flower Power session"
  //       onClose={() => showSelectParticipant(false)}
  //       btnText="Start session"
  //       jobId={jobId}
  //       companyId={companyId}
  //       participants={false}
  //     />
  //   ),
  //   width: 600,
  //   onCancel: () => showSelectParticipant(false),
  // };

  const WSParticipantPopUp = {
    visibility: selectWSParticipant,
    class: "delete_confirmation_modal",
    content: (
      <SelectParticipants
        title="Session participants"
        onClose={() => showSelectWSParticipant(false)}
        btnText="Start session"
        jobId={jobId}
        companyId={companyId}
        participants={sessionData?.participants}
        paragraph="Here is everyone who participated in the Flower Power session. It's recommended to perform this session with the same people."
      />
    ),
    width: 600,
    onCancel: () => showSelectWSParticipant(false),
  };

  useEffect(() => {
    if (jobDetailData?.session?.id) {
      dispatch(getSessionById(jobDetailData?.session?.id));
    }
  }, [jobDetailData?.session?.id]);

  return (
    <>
      <Row className="secondary_header">
        {accountData?.type === "SUPER_ADMIN" && (
          <Col span={24}>
            <Breadcrumb separator={<ArrowIconRight />}>
              <Breadcrumb.Item onClick={() => navigate("/companies")}>
                <Image
                  src="/images/breadcrumb_rightarrow.svg"
                  preview={false}
                  width={16}
                  height={16}
                  style={{ marginRight: "14px" }}
                />
                Companies
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigate(`/company-detail/jobs/${companyId}`)}
              >
                {companyData?.name}
              </Breadcrumb.Item>

              <Breadcrumb.Item>{jobDetailData?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        )}

        {accountData?.type === "COMPANY_ADMIN" && jobUrl !== "my-job" && (
          <Col span={24}>
            <Breadcrumb separator={<ArrowIconRight />}>
              <Breadcrumb.Item
                onClick={() => navigate(`/company-detail/jobs/${companyId}`)}
              >
                <Image
                  src="/images/breadcrumb_rightarrow.svg"
                  preview={false}
                  width={16}
                  height={16}
                  style={{ marginRight: "14px" }}
                />
                My company
              </Breadcrumb.Item>

              <Breadcrumb.Item>{jobDetailData?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        )}
        <Col span={24}>
          <DetailTop
            jobDetailData={jobDetailData}
            jobId={jobId}
            companyId={companyId}
          />
        </Col>
      </Row>

      {Object.keys(accountData)?.length > 0 &&
        accountData?.type === "INDIVIDUAL_CONTRIBUTOR" && (
          <Alert
            message="You cannot make changes to the job or roles that you own. Contact the company administrator if you need any adjustments."
            // icon={<SuccessIcon />}
            type="warning"
            showIcon
            closable
            className="warning_msg"
          />
        )}

      {(jobDetailData?.status === "pending_fp_session" ||
        jobDetailData?.status === "suspended_fp_session") && (
        <Card className="session_card fp_session_area">
          <div className="session_card_top">
            <h3>{companyData?.label_position} detail summary</h3>
            <div className="job_status_tag">
              <Tag
                className={
                  jobDetailData?.status === "suspended_fp_session"
                    ? "defaultTag yellow_tag"
                    : "defaultTag blue_tag"
                }
              >
                <Badge className="default_badge" />
                {jobDetailData?.status === "suspended_fp_session"
                  ? "Suspended FP Session"
                  : "Pending FP Session"}
              </Tag>
            </div>
          </div>
          <EmptySessionCardSessionStart
            image={
              jobDetailData?.status === "suspended_fp_session"
                ? "/images/empty/session-icon-suspended.svg"
                : "/images/empty/session-icon.svg"
            }
            title={
              jobDetailData?.status === "suspended_fp_session"
                ? "Continue Flower Power session"
                : "Perform Flower Power session"
            }
            description={
              jobDetailData?.status === "suspended_fp_session" ? (
                "The session is suspended and all the data is preserved. Click the button below to continue."
              ) : (
                <>
                  Gather people who hold this position or have an understanding
                  about what the people who do hold this position are thinking
                  about
                  <br /> and doing. Start the Flower Power session by clicking
                  the button below.
                </>
              )
            }
            description2="Click if already completed Flower Power session"
            buttonImage="/images/flower-icon.svg"
            buttonText={
              jobDetailData?.session?.id
                ? "Continue FP Session"
                : "Start FP Session"
            }
            sessionId={jobDetailData?.session?.id}
            jobId={jobId}
            companyId={companyId}
            setLoading={setLoading}
          />
        </Card>
      )}

      {(jobDetailData?.status === "pending_ws_session" ||
        jobDetailData?.status === "suspended_ws_session") && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card className="session_card ws_session_area">
              <div className="session_card_top">
                <h3>{companyData?.label_position} detail summary</h3>
                <div className="job_status_tag">
                  <Tag
                    className={
                      jobDetailData?.status === "suspended_ws_session"
                        ? "defaultTag yellow_tag"
                        : "defaultTag blue_tag"
                    }
                  >
                    <Badge className="default_badge" />
                    {
                      STATUS_TYPE[
                        jobDetailData?.status as keyof typeof STATUS_TYPE
                      ]
                    }
                  </Tag>
                </div>
              </div>
              <EmptySessionCard
                image={
                  jobDetailData?.status === "suspended_ws_session"
                    ? "/images/empty/ws-session-suspended.svg"
                    : "/images/empty/Perform_WS_session.svg"
                }
                title={
                  jobDetailData?.status === "suspended_ws_session"
                    ? "Continue the Worksheet session"
                    : "Perform the Worksheet session"
                }
                description={
                  jobDetailData?.status === "suspended_ws_session" ? (
                    "The session is suspended and all the data is preserved. Click the button below to continue."
                  ) : (
                    <>
                      Gather everyone who holds this position one more time and
                      start the session by clicking the button below
                    </>
                  )
                }
                description2="Click if already completed Worksheet session"
                buttonImage="/images/worksheet_session.svg"
                buttonText={
                  jobDetailData?.status === "pending_ws_session"
                    ? "Start worksheet session"
                    : "Continue worksheet session"
                }
                jobId={jobId}
                companyId={companyId}
              />
            </Card>
          </Col>

          <Col span={24}>
            <JobRoles jobDetailData={jobDetailData} setLoading={setLoading} />
          </Col>
        </Row>
      )}

      {(jobDetailData?.status === "pending_approval" ||
        jobDetailData?.status === "approved") && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <JobSummary jobDetailData={jobDetailData} setLoading={setLoading} />
          </Col>

          <Col span={24}>
            <JobRoles jobDetailData={jobDetailData} setLoading={setLoading} />
          </Col>
        </Row>
      )}
      {/* <Popup {...participantPopUp} /> */}
      <Popup {...WSParticipantPopUp} />
    </>
  );
}
