import { Fragment } from "react/jsx-runtime";
import { RoleType } from "../../../../../organisms/MyJob/ducks/types";
import { flowerMapGen } from "../FlowerMapGen";

type PropsType = {
  data: RoleType[];
  activeTab?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: RoleType;
};

export default function FpSessionReady(props: PropsType) {
  const { data, activeTab, setActiveTab } = props;

  return (
    <div className={`flower ${flowerMapGen[data?.length]?.class}`}>
      {data?.map((e: any, i: number) => (
        <Fragment key={i + 1}>
          <div
            className={`leaf leaf-${i + 1} ${
              activeTab ? parseInt(activeTab) === i + 1 && "active" : ""
            }`}
            onClick={() =>
              setActiveTab ? setActiveTab((i + 1).toString()) : null
            }
          >
            <div className="petal_svg">
              {flowerMapGen[data.length].petalShape}
            </div>
            <div className="leaf_text">
              <div className="leaf_detail">
                <h4>
                  {e?.name ? (
                    e?.name
                  ) : (
                    <span style={{ opacity: 0.5 }}>Role names...</span>
                  )}
                </h4>
              </div>
              <div className="leaf_number">{i + 1}</div>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
