import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined } from "@ant-design/icons";
import { Role } from "../../types";
import { Avatar, Button, Image, Input } from "antd";
import { updateRoleDescriptor } from "../../../../organisms/MyJob/ducks/services";

const { TextArea } = Input;
interface PropsType {
  role: Role;
  deleteRole: (id: string | number) => void;
  updateRole?: (id: string | number, descriptor: string) => void;
  roleToUpdate?: any;
  createRole?: any;
  setRoleToUpdate: React.Dispatch<any>;
  sessionDetails?: boolean;
}

export default function RoleCard(props: PropsType) {
  const {
    role,
    deleteRole,
    updateRole,
    roleToUpdate,
    setRoleToUpdate,
    createRole,
    sessionDetails,
  } = props;
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    if (editMode && roleToUpdate?.id) {
      const payload = {
        id: roleToUpdate?.id,
        descriptor: roleToUpdate?.descriptor?.trimStart().trimEnd(),
      };
      updateRoleDescriptor(payload).then(() => {
        setEditMode(!editMode);
        setRoleToUpdate(undefined);
        createRole && createRole();
      });
    }
  };

  const saveOnBlur = () => {
    if (roleToUpdate?.id) {
      const payload = {
        id: roleToUpdate?.id,
        descriptor: roleToUpdate?.descriptor?.trimStart().trimEnd(),
      };
      updateRoleDescriptor(payload).then(() => {
        setRoleToUpdate(undefined);
        setEditMode(false);
      });
    } else {
      setEditMode(false);
    }
  };

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: role.id,
    data: {
      type: "Role",
      role,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        className="role_wrapper is_dragging"
        ref={setNodeRef}
        style={style}
      />
    );
  }

  if (editMode) {
    return (
      <div className="role_wrapper">
        {!sessionDetails && (
          <div className="role_dragger" {...listeners} {...attributes}>
            <HolderOutlined />
          </div>
        )}

        <div className="role_content">
          <div className="role_image">
            {role?.user_image ? (
              <Avatar src={role?.user_image} />
            ) : (
              <div className="table_with_image">
                <Avatar style={{ marginRight: 0 }}>
                  {role?.user_initials}
                </Avatar>
              </div>
            )}
          </div>
          <TextArea
            autoSize
            defaultValue={
              role?.descriptor === "descriptor" ? "" : role?.descriptor
            }
            onBlur={() => saveOnBlur()}
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                toggleEditMode();
                e.preventDefault();
              }
            }}
            onChange={(e) =>
              updateRole &&
              e.target.value?.trimStart().trimEnd() &&
              updateRole(role.id, e.target.value?.trimStart().trimEnd())
            }
            placeholder="Type role descriptor..."
          />
        </div>
        <Button className="role_btn" onClick={() => deleteRole(role.id)}>
          <Image src="/images/close-icon.svg" preview={false} width={8} />
        </Button>
      </div>
    );
  }
  // console.log("role", role);
  return (
    <div ref={setNodeRef} style={style} className="role_wrapper">
      {!sessionDetails && (
        <div className="role_dragger" {...listeners} {...attributes}>
          <HolderOutlined />
        </div>
      )}

      <div className="role_content">
        <div className="role_image">
          {role?.user_image ? (
            <Avatar src={role?.user_image} />
          ) : (
            <div className="table_with_image">
              <Avatar style={{ marginRight: 0 }}>{role?.user_initials}</Avatar>
            </div>
          )}
        </div>
        {/* <p>{role?.descriptor === "descriptor" ? "" : role?.descriptor}</p> */}
        <TextArea
          autoSize
          autoFocus={role?.descriptor === "descriptor" && !isDragging}
          style={{ border: "1px solid #fff" }}
          defaultValue={
            role?.descriptor === "descriptor" ? "" : role?.descriptor
          }
          placeholder="Type role descriptor..."
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          disabled={sessionDetails}
          onClick={() => setEditMode(true)}
          onFocus={() => setEditMode(true)}
          onBlur={() => setEditMode(false)}
        />
      </div>
      {!sessionDetails && (
        <Button className="role_btn" onClick={() => deleteRole(role.id)}>
          <Image src="/images/close-icon.svg" preview={false} width={8} />
        </Button>
      )}
    </div>
  );
}
