import { Tabs } from "antd";
import JobTabs from "../JobTabs";
import { useEffect, useState } from "react";
import {
  JobDetailType,
  RoleType,
} from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  jobDetailData: JobDetailType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function FlowerView(props: PropsType) {
  const { jobDetailData, setLoading } = props;
  const [activeTab, setActiveTab] = useState("1");

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (jobDetailData?.roles?.length > 0) {
      setActiveTab("1");
    }
  }, [jobDetailData]);

  return (
    <div className="roles_inner_tab">
      <Tabs
        activeKey={activeTab}
        onChange={onTabChange}
        items={jobDetailData?.roles?.map((e: RoleType, i: number) => ({
          key: `${i + 1}`,
          label: (
            <div className={`tab-label fp-tabs-${i + 1}`}>
              <span className="tab-number">{i + 1}</span> {e?.name}
            </div>
          ),
          children: (
            <JobTabs
              data={jobDetailData?.roles}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              activeData={e}
              activeNumber={i + 1}
              jobName={jobDetailData?.name}
              departmentName={jobDetailData?.department_name}
              setLoading={setLoading}
            />
          ),
        }))}
      />
    </div>
  );
}
