import { Tag } from "antd";
import {
  JobDetailType,
  RoleType,
} from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  jobDetailData: JobDetailType;
};

export default function TableView(props: PropsType) {
  const { jobDetailData } = props;

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div className="job_roles_table">
      <div className="table_inner">
        <div className="table_header">
          <div className={`top_role top_role_${jobDetailData?.roles?.length}`}>
            {jobDetailData?.name}
          </div>
          {jobDetailData?.roles?.length > 0 &&
            jobDetailData?.roles?.map((role: RoleType, index) => (
              <div
                className={`header_cols cols_${jobDetailData?.roles?.length}`}
                key={role?.id}
              >
                <div className="header_label">
                  {role?.name}
                  <h6 className={`tagNumber_${index + 1}`}>{index + 1}</h6>
                </div>
              </div>
            ))}
        </div>
        <div className="table_body">
          <div className="body_inner">
            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Purpose statement</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    {jobDetailData?.purpose_statement === "N/A" ? (
                      "Not applicable"
                    ) : (
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          jobDetailData?.purpose_statement as string
                        )}
                        className="rows_para"
                      />
                    )}
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Objectives</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    {jobDetailData?.objectives === "N/A" ? (
                      "Not applicable"
                    ) : (
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          jobDetailData?.objectives as string
                        )}
                        className="rows_para"
                      />
                    )}
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Key results</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    <div className="rows_para">
                      {role?.key_results?.length > 0 ? (
                        <ul>
                          {role?.key_results?.map((key: string, i: number) => (
                            <li key={i}>{key}</li>
                          ))}
                        </ul>
                      ) : (
                        "Not applicable"
                      )}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Workflows</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    <div className="rows_para">
                      {role?.workflows?.length > 0
                        ? role?.workflows?.map(
                            (workflow: string, i: number) => (
                              <Tag
                                className="ant-tag defaultTag white_tag"
                                key={i}
                              >
                                {workflow}
                              </Tag>
                            )
                          )
                        : "Not applicable"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Procedures/WIs/Policies</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    {jobDetailData?.procedures === "N/A" ? (
                      "Not applicable"
                    ) : (
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          jobDetailData?.procedures as string
                        )}
                        className="rows_para"
                      />
                    )}
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Entities</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    {jobDetailData?.entities === "N/A" ? (
                      "Not applicable"
                    ) : (
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          jobDetailData?.entities as string
                        )}
                        className="rows_para"
                      />
                    )}
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Skills</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    <div className="rows_para">
                      {role?.skills?.length > 0
                        ? role?.skills?.map((skill: string, i: number) => (
                            <Tag
                              className="ant-tag defaultTag white_tag"
                              key={i}
                            >
                              {skill}
                            </Tag>
                          ))
                        : "Not applicable"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Technologies</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    <div className="rows_para">
                      {role?.technologies?.length > 0
                        ? role?.technologies?.map(
                            (technology: string, i: number) => (
                              <Tag
                                className="ant-tag defaultTag white_tag"
                                key={i}
                              >
                                {technology}
                              </Tag>
                            )
                          )
                        : "Not applicable"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Teams</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    <div className="rows_para">
                      {role?.teams?.length > 0
                        ? role?.teams?.map((team: string, i: number) => (
                            <Tag
                              className="ant-tag defaultTag white_tag"
                              key={i}
                            >
                              {team}
                            </Tag>
                          ))
                        : "Not applicable"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Meetings</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    <div className="rows_para">
                      {role?.meetings?.length > 0
                        ? role?.meetings?.map((meeting: string, i: number) => (
                            <Tag
                              className="ant-tag defaultTag white_tag"
                              key={i}
                            >
                              {meeting}
                            </Tag>
                          ))
                        : "Not applicable"}
                    </div>
                  </div>
                ))}
            </div>

            <div className="table_rows">
              <div
                className={`rows_head rows_head_${jobDetailData?.roles?.length}`}
              >
                <div className="ant-typography">Department</div>
              </div>
              {jobDetailData?.roles?.length > 0 &&
                jobDetailData?.roles?.map((role: RoleType) => (
                  <div
                    className={`rows_cols rows_cols_${jobDetailData?.roles?.length}`}
                    key={role?.id}
                  >
                    <div className="rows_para">{role?.department ?? "-"}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
