import { Fragment, useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Avatar,
  Image,
  Select,
  Alert,
  Divider,
  Space,
  Tour,
  TourProps,
} from "antd";
import {
  Participants,
  SessionTypeResponse,
} from "../../../../../../organisms/MyJob/ducks/types";
import { getInitials } from "../../../../../../config/constants";
import { SearchIcon } from "../../../../../CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../../../../config/hooks";
import {
  getParticipantsForSession,
  getSessionById,
} from "../../../../../../organisms/MyJob/ducks/actions";
import ErrorMessage from "../../../../../Toasts/ErrorMessage";
import { AxiosError } from "axios";
import { updateSessionById } from "../../../../../../organisms/MyJob/ducks/services";
import { useLocation } from "react-router-dom";
import CreateEmployee from "../../../../../CreateEmployee";
import { Popup } from "../../../../../Popup";

const { Option } = Select;
type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sessionData: SessionTypeResponse;
};

export default function StepSelectParticipants(props: PropsType) {
  const { setCurrent, setLoading, sessionData } = props;
  const dispatch = useAppDispatch();
  const url = useLocation();
  const sessionId = url?.pathname.split("/")[2];
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedParticipants, setSelectedParticipantsData] = useState<
    Participants[]
  >([]);
  const [apiUsers, setApiUsers] = useState<{ id: string }[]>([]);
  const [newEmpArray, setNewEmpArray] = useState<string[]>([]);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);
  const participantsData = useAppSelector(
    (state) => state?.jobDetail?.participantsData
  );
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const onSubmit = () => {
    const allIdsPresent = selectedItems?.every((id) =>
      apiUsers?.some((user) => user?.id === id)
    );
    if (!allIdsPresent || selectedItems?.length !== apiUsers?.length) {
      setLoading(true);
      const payload = {
        last_session_screen: 1,
        employees: selectedItems,
      };

      updateSessionById(payload, sessionId)
        .then(() => {
          dispatch(getSessionById(sessionId));
          setLoading(false);
          setCurrent(1);
        })
        .catch((e: unknown) => {
          if (e instanceof AxiosError) {
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
            setLoading(false);
          } else {
            ErrorMessage("Something went wrong");
            setLoading(false);
          }
        });
    } else {
      setCurrent(1);
    }
  };

  useEffect(() => {
    if (sessionData?.company_id && sessionData?.job_id) {
      const payload = {
        job_id: sessionData?.job_id,
        company_id: sessionData?.company_id,
      };

      dispatch(getParticipantsForSession(payload));
    }
  }, [dispatch, sessionData?.company_id, sessionData?.job_id]);

  // const filteredOptions = participantsData?.filter((o: any) =>
  //   selectedItems.includes(o?.id)
  // );

  // const completeData = participantsData?.filter((o: any) =>
  //   selectedItems.includes(o?.id)
  // );

  // const setSelectedParticipants = (value: string) => {
  //   setSelectedItems([...selectedItems, ...value]);
  // };

  useEffect(() => {
    if (sessionData?.participants?.length > 0) {
      const dummyArray: string[] = [];
      sessionData?.participants?.map((e) => e?.id && dummyArray.push(e?.id));

      setSelectedItems([...dummyArray]);
      setApiUsers(sessionData?.participants);
    }
  }, [sessionData]);
  const [createEmployee, setCreateEmployee] = useState(false);

  const updateApi = (newEmp: string) => {
    setSelectedItems([...selectedItems, newEmp]);
    setNewEmpArray([...newEmpArray, newEmp]);
    if (sessionData?.company_id && sessionData?.job_id) {
      const payload = {
        job_id: sessionData?.job_id,
        company_id: sessionData?.company_id,
      };

      dispatch(getParticipantsForSession(payload));
    }
  };

  const createJob = {
    visibility: createEmployee,
    class: "upload_photo_modal",
    content: (
      <CreateEmployee
        title="Create employee"
        onClose={() => setCreateEmployee(false)}
        btnText={`Create ${companyData?.label_position?.toLowerCase()}`}
        companyId={sessionData?.company_id}
        updateApi={updateApi}
        labelPosition={companyData?.label_position}
        showJob={true}
      />
    ),
    width: 600,
    onCancel: () => setCreateEmployee(false),
  };

  const buttonRef = useRef(null);
  const [employeeTour, setEmployeeTour] = useState<boolean>(false);
  const employeeStep: TourProps["steps"] = [
    {
      title: "New employees",
      description: (
        <>
          New employees will receive an email with their account credentials.{" "}
          <br /> They must log in before the session starts.
        </>
      ),
      target: () => buttonRef.current,
    },
  ];

  const stepCreateEmployee = () => {
    localStorage.setItem("tourEmployee", "done");
    setEmployeeTour(false);
  };

  useEffect(() => {
    const isTourEmployee = localStorage.getItem("tourEmployee");
    // console.log("isTourEmployee", isTourEmployee);

    if (
      !isTourEmployee &&
      isTourEmployee !== "done" &&
      accountData?.type === "COMPANY_ADMIN" &&
      newEmpArray?.length > 0 &&
      !createEmployee &&
      selectedItems?.length < 2
    ) {
      setEmployeeTour(true);
    }
  }, [
    localStorage?.getItem("tourEmployee"),
    accountData?.type,
    newEmpArray,
    createEmployee,
    selectedItems,
  ]);
  console.log("selectedItems", selectedItems);
  useEffect(() => {
    const dummyArray: Participants[] = participantsData?.filter((o: any) =>
      selectedItems.includes(o?.id)
    );

    setSelectedParticipantsData(dummyArray);
  }, [selectedItems]);
  return (
    <Card className="fp_layout_content session_card">
      <div className="cardContent">
        {sessionData?.last_session_screen > 0 && (
          <Alert
            style={{ marginBottom: "16px" }}
            message="Changing the participants list will delete previous session activities."
            type="info"
            className="warning_msg alert_for_session"
            showIcon
          />
        )}
        <div
          className="select_individual_head select_participants"
          style={{ flexDirection: "column" }}
        >
          <div className="individual_left ">
            <h4>Select participants</h4>
            <p>Select people who you’re having the session with.</p>
          </div>
          <div ref={buttonRef} style={{ width: "100%" }}>
            <Select
              className="participant_select"
              placeholder="Search employees"
              value={selectedItems}
              onChange={setSelectedItems}
              style={{ width: "100%" }}
              suffixIcon={<SearchIcon />}
              showSearch
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option?.label
                  ? option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  : option?.children?.toString()?.includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      type="text"
                      className="add_employee"
                      onClick={() => setCreateEmployee(true)}
                    >
                      <Image
                        src="/images/user-icon.svg"
                        preview={false}
                        width={16}
                      />{" "}
                      Add employee
                    </Button>
                  </Space>
                </>
              )}
            >
              {participantsData?.length > 0 &&
                participantsData?.map((e: any) => (
                  <Fragment key={e?.id}>
                    <Option
                      value={e?.id}
                      label={`${e?.first_name} ${e?.last_name}`}
                    >
                      <div className="detail_option">
                        {e?.image?.url ? (
                          <div className="participant_avatar">
                            <Avatar src={e?.image?.url} />
                          </div>
                        ) : (
                          <div className="participant_avatar">
                            <Avatar>
                              {getInitials(e?.first_name, e?.last_name)}
                            </Avatar>
                          </div>
                        )}

                        <div className="user_name">
                          {e?.first_name ?? ""} {e?.last_name ?? ""}
                        </div>
                        <div className="user_position">
                          {e?.job?.name ?? (
                            <span style={{ opacity: 0.5 }}>No job title</span>
                          )}
                        </div>
                      </div>
                    </Option>
                  </Fragment>
                ))}
            </Select>
          </div>
          {selectedParticipants?.length > 0 && (
            <div className="participants_modal_wrapper">
              {selectedParticipants?.map((e: Participants) => (
                <Card className="participants_card_modal" key={e?.id}>
                  {e?.image ? (
                    <div className="participant_avatar">
                      <Avatar src={e?.image?.url} />
                    </div>
                  ) : (
                    <div className="participant_avatar">
                      <Avatar>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    </div>
                  )}
                  <div className="participant_info">
                    <h6>{`${e?.first_name ?? ""} ${e?.last_name ?? ""}`}</h6>
                    <p>
                      {e?.job?.name ?? (
                        <span style={{ opacity: 0.5 }}>No job title</span>
                      )}
                    </p>
                  </div>
                  <div className="participant_check">
                    <Button
                      type="link"
                      onClick={() =>
                        setSelectedItems(
                          selectedItems?.filter((item) => item !== e?.id)
                        )
                      }
                    >
                      <Image
                        src="/images/close-icon.svg"
                        preview={false}
                        width={16}
                        height={16}
                      />
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          )}
        </div>

        <div className="participants_card">
          <Button
            htmlType="button"
            className="icon_dark_btn"
            type="primary"
            size="large"
            disabled={selectedItems?.length === 0}
            onClick={() => onSubmit()}
          >
            Continue
          </Button>
        </div>
      </div>
      <Popup {...createJob} />
      <Tour
        open={employeeTour}
        onClose={() => stepCreateEmployee()}
        steps={employeeStep}
        placement="leftTop"
      />
    </Card>
  );
}
