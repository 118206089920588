import { Fragment, useState } from "react";
import { Row, Col, Button, Tag, Card, Badge, Dropdown } from "antd";
import { ToggleDownIcon, ToggleUpIcon } from "../../../../atoms/CustomIcons";
import { JobDetailType } from "../../../../organisms/MyJob/ducks/types";
import { updateJobById } from "../../../../organisms/CompanyDetails/ducks/services";
import { AxiosError } from "axios";
import ErrorMessage from "../../../Toasts/ErrorMessage";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getJobDetails } from "../../../../organisms/MyJob/ducks/actions";
import { STATUS_TYPE } from "../../../../config/constants";
import SuccessMessage from "../../../Toasts/SuccessMessage";

type PropsType = {
  jobDetailData: JobDetailType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function JobSummary(props: PropsType) {
  const { jobDetailData, setLoading } = props;
  const [showFullInfo, setShowFullInfo] = useState(false);
  const url = useLocation();
  const dispatch = useAppDispatch();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const changeJobStatus = (status: string) => {
    setLoading(true);
    const payload = {
      status: status,
      company_id: companyId,
    };
    updateJobById(payload, jobId)
      .then(() => {
        dispatch(getJobDetails(jobId));
        setLoading(false);
        SuccessMessage("Status changed");
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder tag_statusBlue"
          onClick={() => changeJobStatus("pending_approval")}
        >
          Pending approval{" "}
          {jobDetailData?.status === "pending_approval" && (
            <img src="/images/approved_icon.svg" alt="" />
          )}
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => changeJobStatus("approved")}
          className="noBorder tag_statusGreen"
        >
          Approved{" "}
          {jobDetailData?.status === "approved" && (
            <img src="/images/approved_icon.svg" alt="" />
          )}
        </Button>
      ),
    },
  ];

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <Card className="my_job">
      <div className="my_job_summary">
        <h3>{companyData?.label_position} detail summary</h3>
        <Button
          className="toggle_info"
          onClick={() => setShowFullInfo(!showFullInfo)}
        >
          {showFullInfo ? (
            <>
              Hide info <ToggleUpIcon />
            </>
          ) : (
            <>
              View full info <ToggleDownIcon />
            </>
          )}
        </Button>
      </div>

      <Row className="job_summary_thumbs">
        <Col flex="0 0 37%">
          <div style={{ marginBottom: "24px" }}>
            <h4>Purpose statement</h4>
            <div
              dangerouslySetInnerHTML={createMarkup(
                jobDetailData?.purpose_statement as string
              )}
            />
          </div>

          {showFullInfo && (
            <div className="show_full_info">
              <div className="full_info_wrap">
                <h4> Key results </h4>
                <div className="large_tags">
                  {jobDetailData?.key_results?.length > 0
                    ? jobDetailData?.key_results?.map(
                        (e: string, i: number) => (
                          <Fragment key={i}>
                            <Tag>{e}</Tag>
                          </Fragment>
                        )
                      )
                    : "Not applicable"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Workflows</h4>
                <div className="large_tags">
                  {jobDetailData?.workflows?.length > 0
                    ? jobDetailData?.workflows?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "Not applicable"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Skills</h4>
                <div className="large_tags">
                  {jobDetailData?.skills?.length > 0
                    ? jobDetailData?.skills?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "Not applicable"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Teams</h4>
                <div className="large_tags">
                  {jobDetailData?.teams?.length > 0
                    ? jobDetailData?.teams?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "Not applicable"}
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col flex="0 0 37%">
          <div style={{ marginBottom: "24px" }}>
            <h4>Objectives</h4>
            {jobDetailData?.objectives === "N/A" ? (
              "Not applicable"
            ) : (
              <div
                dangerouslySetInnerHTML={createMarkup(
                  jobDetailData?.objectives as string
                )}
              />
            )}
          </div>

          {showFullInfo && (
            <div className="show_full_info">
              <div className="full_info_wrap">
                <h4>Entities</h4>
                {jobDetailData?.entities === "N/A" ? (
                  "Not applicable"
                ) : (
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      jobDetailData?.entities as string
                    )}
                  />
                )}
              </div>

              <div className="full_info_wrap">
                <h4>Procedures/WIs/Policies</h4>
                {jobDetailData?.procedures === "N/A" ? (
                  "Not applicable"
                ) : (
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      jobDetailData?.procedures as string
                    )}
                  />
                )}
              </div>

              <div className="full_info_wrap">
                <h4>Technologies</h4>
                <div className="large_tags">
                  {jobDetailData?.technologies?.length > 0
                    ? jobDetailData?.technologies?.map(
                        (e: string, i: number) => (
                          <Fragment key={i}>
                            <Tag>{e}</Tag>
                          </Fragment>
                        )
                      )
                    : "Not applicable"}
                </div>
              </div>

              <div className="full_info_wrap">
                <h4>Meetings</h4>
                <div className="large_tags">
                  {jobDetailData?.meetings?.length > 0
                    ? jobDetailData?.meetings?.map((e: string, i: number) => (
                        <Fragment key={i}>
                          <Tag>{e}</Tag>
                        </Fragment>
                      ))
                    : "Not applicable"}
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col flex="0 0 23%">
          <div style={{ height: "225px" }}>
            <h4>Status</h4>
            <div className="status_column">
              <Tag
                className={`${
                  jobDetailData?.status === "approved"
                    ? "defaultTag green_tag"
                    : "defaultTag light_blue_tag"
                }`}
              >
                <Badge className="default_badge" />
                {STATUS_TYPE[jobDetailData?.status as keyof typeof STATUS_TYPE]}
              </Tag>
              {accountData?.type !== "INDIVIDUAL_CONTRIBUTOR" && (
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="actionButton"
                >
                  <ToggleDownIcon className="toggleIconStatus" />
                </Dropdown>
              )}
            </div>

            {/* <div
              className="card_role"
              style={{
                width: "60%",
                backgroundColor: "#fff",
                border: "unset",
                marginBottom: "28px",
              }}
            >
              <p className="role_title">Reports to</p>
              <div
                className="role_name"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Project owner <img src="/images/visit_icon.svg" />
                <div>
                  <Avatar.Group
                    className="avatarGroup"
                    size="small"
                    max={{
                      count: 3,
                      style: {
                        color: "#161616",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        fontSize: "10px",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <Avatar>AR</Avatar>
                    <Avatar src="/images/avatar_1.jpg" />
                    <Avatar>SN</Avatar>
                  </Avatar.Group>
                </div>
              </div>
            </div> */}
            {showFullInfo && (
              <div
                className="card_role"
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "unset",
                }}
              >
                {/* <p className="role_title">Mentored by</p>
                <div
                  className="role_name"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "28px",
                  }}
                >
                  <div>
                    <Avatar.Group
                      className="avatarGroup"
                      size="small"
                      max={{
                        count: 1,
                        style: {
                          color: "#161616",
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          fontSize: "10px",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <Avatar>MB</Avatar>
                    </Avatar.Group>
                  </div>
                  Mike B.
                  <span className="ant-tag defaultTag white_tag">
                    Project Owner
                  </span>
                </div>
                <p className="role_title">Last updated</p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img src="/images/calendar_icon.svg" alt="" /> Jun 12, 2024
                </p> */}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
