import { useEffect, useState } from "react";
import { Card, Button } from "antd";
import { useLocation } from "react-router-dom";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon } from "../../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getJobDetails } from "../../../../organisms/MyJob/ducks/actions";
import Step1 from "./Step1";
import Step2 from "./Step2";
import ConfirmDelete from "../../../../atoms/ConfirmDelete";
import { Popup } from "../../../../atoms/Popup";
import { updateJobById } from "../../ducks/services";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import { AxiosError } from "axios";
import StepSelectParticipants from "./StepSelectParticipants";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";

export default function SessionSteps() {
  const dispatch = useAppDispatch();
  const url = useLocation();
  const jobId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const jobDetailData = useAppSelector(
    (state) => state?.jobDetail?.jobDetailData
  );

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [confirmEnding, setConfirmEnding] = useState(false);
  const [confirmSuspend, setConfirmSuspend] = useState(false);

  const closeAndEndSession = (type: string) => {
    setLoading(true);
    const payload = {
      status: type === "end" ? "pending_ws_session" : "suspended_ws_session",
      company_id: companyId,
    };
    updateJobById(payload, jobId)
      .then(() => {
        if (type === "end") {
          SuccessMessage("Session canceled.");
        } else {
          SuccessMessage("Session suspended.");
        }
        setTimeout(() => {
          setLoading(false);
          window.location.assign(`/job-detail/${jobId}/${companyId}`);
        }, 1000);
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  const confirmEndingPopup = {
    visibility: confirmEnding,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm cancelation"
        heading="Cancel Worksheet session?"
        paragraph_1="Your progress will be lost and you'll need to start over."
        onClose={() => setConfirmEnding(false)}
        btnText="Cancel session"
        btnApi={() => closeAndEndSession("end")}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmEnding(false),
  };

  const confirmSuspendPopup = {
    visibility: confirmSuspend,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm pause"
        heading="Pause Worksheet session?"
        paragraph_1="Your progress will be saved. Next time, you can resume the session from where you left off."
        onClose={() => setConfirmSuspend(false)}
        btnText="Pause session"
        btnApi={() => closeAndEndSession("suspend")}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmSuspend(false),
  };

  const steps = [
    {
      title: "Center Person",
      content: <Step1 setCurrent={setCurrent} setLoading={setLoading} />,
    },
    {
      title: "WS Participants",
      content: (
        <StepSelectParticipants
          setCurrent={setCurrent}
          setLoading={setLoading}
          sessionId={jobDetailData?.detailData?.session?.id}
          jobId={jobId}
          companyId={jobDetailData?.detailData?.company_id}
        />
      ),
    },
    {
      title: "Link",
      content: <Step2 setLoading={setLoading} />,
    },
  ];

  useEffect(() => {
    if (jobDetailData?.detailData?.status === "pending_ws_session") {
      setCurrent(0);
    } else if (
      jobDetailData?.detailData?.status === "suspended_ws_session" ||
      jobDetailData?.detailData?.status === "pending_approval"
    ) {
      setCurrent(2);
    }
  }, [setCurrent, jobDetailData?.detailData?.status]);

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  return (
    <FullPageLayout loading={loading || jobDetailData?.loading}>
      <Card className="fp_layout_title session_title">
        <div className="mainTitle">
          <div className="session_head_left">
            <Button
              className="icon_grey_btn"
              icon={<CloseIcon />}
              onClick={() => setConfirmEnding(true)}
            >
              Cancel session
            </Button>
            <h3>Job & role to 14 point worksheet session</h3>
            <div className="session_avatar"></div>
          </div>
          <div className="session_right_head">
            <Button
              className="icon_grey_btn"
              onClick={() => setConfirmSuspend(true)}
            >
              Pause & save
            </Button>
          </div>
        </div>
      </Card>
      <div className="centered_content">{steps[current].content}</div>

      <Popup {...confirmEndingPopup} />
      <Popup {...confirmSuspendPopup} />
    </FullPageLayout>
  );
}
