import { Fragment, useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import ColumnContainer from "./Components/ColumnContainer";
import RoleCard from "./Components/RoleCard";
import { Column, Id, Role } from "./types";
import BasketContainer from "./Components/BasketContainer";
import { Button, Col, Row, Image, Avatar } from "antd";
import {
  addRoleDescriptor,
  deleteRoleDescriptorById,
} from "../../organisms/MyJob/ducks/services";
import { SessionTypeResponse } from "../../organisms/MyJob/ducks/types";
import { getInitials } from "../../config/constants";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";
import { AxiosError } from "axios";

interface PropsType {
  roleData: Role[];
  roles: Role[];
  setRoles: React.Dispatch<React.SetStateAction<Role[]>>;
  columns: Column[];
  setColumns: React.Dispatch<React.SetStateAction<Column[]>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  transformData: () => void;
  sessionId: string;
  sessionData: SessionTypeResponse;
  sessionDetails?: boolean;
  flowerData?: [];
}

export default function KanbanBoard(props: PropsType) {
  const {
    roleData,
    setCurrent,
    transformData,
    roles,
    setRoles,
    sessionId,
    sessionData,
    columns,
    setColumns,
    sessionDetails,
    flowerData,
  } = props;
  // console.log("roleData", roleData);

  const [columnBasket, setColumnBasket] = useState<Column[]>([]);
  const [columnsWithId, setColumnsWidthId] = useState<Column[]>([]);
  const [roleToUpdate, setRoleToUpdate] = useState<any>(undefined);
  //   const [activeColumn, setActiveColumn] = useState(null);
  const [activeRole, setActiveRole] = useState(null);
  const columnsId = useMemo(() => columns?.map((col) => col?.id), [columns]);

  useEffect(() => {
    if (roleData && flowerData) {
      const updatedRoleData: any[] = [...roleData];

      const petalsWithEmptyDescriptors = flowerData.filter(
        (petal: any) => petal.descriptors.length === 0
      );

      petalsWithEmptyDescriptors?.forEach((_item, index: number) => {
        updatedRoleData.push({
          column_id: `dummy_column_id_${index}`,
          descriptor: "dummy_descriptor",
        });
      });

      const newGroupedData = updatedRoleData.reduce(
        (
          acc: { [key: string]: { column_id: string; descriptors: string[] } },
          item
        ) => {
          const { column_id } = item;
          if (!acc[column_id]) {
            acc[column_id] = {
              column_id: column_id?.toString(),
              descriptors: [item?.descriptor],
            };
          } else {
            acc[column_id]?.descriptors.push(item?.descriptor);
          }
          return acc;
        },
        {}
      );

      const columnsData = Object.values(newGroupedData); // Use newGroupedData

      const columnArray: Column[] = [];
      const columnBasket: Column[] = [
        {
          id: "basket",
          title: "Basket",
        },
      ];
      columnsData?.map((col) => {
        columnArray.push({
          id: col.column_id,
          title: `Column ${columnArray.length + 1}`,
        });
      });
      const columnHaveBasket = columnArray.filter((col) => col.id === "basket");

      if (columnHaveBasket?.length > 0) {
        setColumns(columnArray);
      } else {
        setColumns([...columnBasket, ...columnArray]);
      }

      setRoles(roleData);
    }
  }, [setRoles, roleData, flowerData]);

  // console.log("columns", columns);

  const createNewColumn = () => {
    const columnToAdd = {
      id: generateId().toString(),
      title: `Column ${columns.length + 1}`,
    };

    setColumns([...columns, columnToAdd]);
  };

  useEffect(() => {
    if (columns) {
      const basketColumn = columns.filter(
        (col: Column) => col?.id === "basket"
      );
      const IdColumn = columns.filter((col: Column) => col?.id !== "basket");

      setColumnBasket(basketColumn);
      setColumnsWidthId(IdColumn);
    }
  }, [columns]);

  const deleteColumn = (id: Id) => {
    const filteredColumns = columns?.filter((e) => e?.id !== id);
    const newRoles = roles?.filter((role) => role?.column_id !== id);
    setColumns(filteredColumns);
    setRoles(newRoles);
  };

  const onDragStart = (event: DragStartEvent) => {
    // if (event.active.data.current?.type === "Column") {
    //   setActiveColumn(event.active.data.current.column);
    //   return;
    // }

    // console.log("event", event);

    if (event.active.data.current?.type === "Role") {
      setActiveRole(event.active.data.current.role);
      return;
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    // setActiveColumn(null);
    // console.log("event en", event);
    setActiveRole(null);
    const { over, active } = event;
    if (!over) return;

    const activeColumnId = active.id;
    const overColumnId = over.id;

    if (activeColumnId === overColumnId) return;

    if (active.id !== over.id) {
      setColumns((column) => {
        const oldIndex = column.findIndex((col) => col?.id === activeColumnId);
        const newIndex = column.findIndex((col) => col?.id === overColumnId);

        return arrayMove(column, oldIndex, newIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  const createRole = () => {
    // const existingDescriptorIndex = roles?.findIndex(
    //   (item) => item.descriptor === "descriptor"
    // );
    // if (existingDescriptorIndex !== -1) {
    //   ErrorMessage("Fill the old role descriptor first");
    //   return;
    // }

    const payload = {
      session_id: sessionId,
      descriptor: "descriptor",
      index: roles?.length + 1,
    };

    addRoleDescriptor(payload)
      .then((res) => {
        setRoles([...roles, res.data]);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  // console.log("roles", roles);

  const deleteRole = (id: Id) => {
    const filteredRoles = roles?.filter((e) => e?.id !== id);
    deleteRoleDescriptorById(id).then(() => {
      setRoles(filteredRoles);
    });
  };

  const onDragOver = (event: DragOverEvent) => {
    const { over, active } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveARole = active.data.current?.type === "Role";
    const isOverARole = over.data.current?.type === "Role";

    if (!isActiveARole) return;

    if (isActiveARole && isOverARole) {
      setRoles((roles) => {
        const activeIndex = roles?.findIndex((role) => role?.id === activeId);
        const overIndex = roles?.findIndex((role) => role?.id === overId);

        roles[activeIndex].column_id = roles[overIndex].column_id;
        return arrayMove(roles, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";

    if (isActiveARole && isOverAColumn) {
      setRoles((roles: Role[]) => {
        const activeIndex = roles?.findIndex((t) => t?.id === activeId);

        roles[activeIndex].column_id = overId;
        return arrayMove(roles, activeIndex, activeIndex);
      });
    }
  };

  const generateId = () => {
    return Math.floor(Math.random() * 10001);
  };

  // console.log("roles", roles);

  const updateRole = (id: Id, descriptor: string) => {
    const updatedLocal = roles.map((role: Role) => {
      if (role.id !== id) {
        return role;
      }
      return { ...role, descriptor };
    });
    const findRoleById = roles.find((role) => role.id === id);
    const updatedRole = { ...findRoleById, descriptor };
    setRoleToUpdate(updatedRole);
    setRoles(updatedLocal);
  };
  return (
    <div className="kanban_board">
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <Row>
          <SortableContext items={columnsId}>
            <Col span={8}>
              {columnBasket?.map((col: Column) => (
                <Fragment key={col?.id}>
                  <BasketContainer
                    column={col}
                    createRole={createRole}
                    roles={roles?.filter((e) => e?.column_id === "basket")}
                    deleteRole={deleteRole}
                    setCurrent={setCurrent}
                    transformData={transformData}
                    updateRole={updateRole}
                    roleToUpdate={roleToUpdate}
                    setRoleToUpdate={setRoleToUpdate}
                    columnsLength={columns?.length}
                    sessionData={sessionData}
                    sessionDetails={sessionDetails}
                  />
                </Fragment>
              ))}
            </Col>
            <Col span={16}>
              <div className="role_right_wrapper">
                <div className="role_right_top">
                  <h6>Flower petals</h6>
                  <div className="facilitator_details">
                    {sessionData?.c_person?.image?.url ? (
                      <div className="participant_avatar">
                        <Avatar src={sessionData?.c_person?.image?.url} />
                      </div>
                    ) : (
                      <div className="participant_avatar">
                        <Avatar>
                          {getInitials(
                            sessionData?.c_person?.first_name,
                            sessionData?.c_person?.last_name
                          )}
                        </Avatar>
                      </div>
                    )}

                    <div style={{ marginLeft: 8, display: "flex" }}>
                      <h4>
                        {sessionData?.c_person?.first_name ?? ""}{" "}
                        {sessionData?.c_person?.last_name ?? ""}{" "}
                      </h4>
                      <p>
                        |{" "}
                        {sessionData?.c_person?.job?.name ?? (
                          <span style={{ opacity: 0.5 }}>No job title</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column_petal_wrapper">
                  {columnsWithId?.map((col: Column, index: number) => (
                    <div className="petal_box" key={col?.id}>
                      <ColumnContainer
                        column={col}
                        deleteColumn={deleteColumn}
                        roles={roles?.filter(
                          (e) =>
                            e?.column_id === col?.id &&
                            e?.column_id !== "basket"
                        )}
                        deleteRole={deleteRole}
                        index={index}
                        updateRole={updateRole}
                        roleToUpdate={roleToUpdate}
                        setRoleToUpdate={setRoleToUpdate}
                        sessionDetails={sessionDetails}
                      />
                    </div>
                  ))}
                  {columns?.length < 25 && !sessionDetails && (
                    <div className="petal_box">
                      <Button
                        className="addPetal-btn"
                        onClick={createNewColumn}
                      >
                        <Image
                          src="/images/plus-icon.svg"
                          preview={false}
                          width={24}
                        />{" "}
                        Add petal
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </SortableContext>
        </Row>

        {createPortal(
          <DragOverlay>
            {/* {activeColumn && (
              <ColumnContainer
                column={activeColumn}
                deleteColumn={deleteColumn}
                roles={roles?.filter(
                  (role: Role) => role?.column_id === activeColumn
                )}
                deleteRole={deleteRole}
              />
            )} */}
            {activeRole && (
              <RoleCard
                role={activeRole}
                deleteRole={deleteRole}
                setRoleToUpdate={setRoleToUpdate}
              />
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );
}
