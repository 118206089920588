import { Fragment, useEffect, useState } from "react";
import { Card, Button, Avatar } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import FullPageLayout from "../../../templates/FullPageLayout";
import { CloseIcon } from "../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  clearSessionById,
  getSessionById,
} from "../../../organisms/MyJob/ducks/actions";
import { getInitials } from "../../../config/constants";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step6 from "./Step6";
import Step5 from "./Step5";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";

export default function SessionDetails() {
  const dispatch = useAppDispatch();
  const url = useLocation();
  const navigate = useNavigate();
  const sessionId = url?.pathname.split("/")[2];
  const sessionData = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.data
  );
  const sessionLoader = useAppSelector(
    (state) => state?.jobDetail?.sessionData?.loading
  );

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: "Select Center Person",
      content: <Step1 setCurrent={setCurrent} sessionData={sessionData} />,
    },
    {
      title: "Link",
      content: (
        <Step2
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
        />
      ),
    },
    {
      title: "Petal Create",
      content: (
        <Step3
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Ready to split",
      content: (
        <Step4
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
        />
      ),
    },
    {
      title: "Split the group",
      content: (
        <Step5
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Link Copy for Roles",
      content: (
        <Step6
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
          loading={loading}
        />
      ),
    },
    {
      title: "Insert Serious Funny",
      content: (
        <Step7
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Finalized",
      content: (
        <Step8
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Category",
      content: (
        <Step9
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: "Statement",
      content: (
        <Step10
          setCurrent={setCurrent}
          sessionData={sessionData}
          sessionId={sessionId}
          setLoading={setLoading}
        />
      ),
    },
  ];

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionById(sessionId));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    dispatch(clearSessionById());
  }, [dispatch]);

  return (
    <FullPageLayout loading={loading || sessionLoader}>
      {steps[current]?.title !== "Final" && (
        <Card className="fp_layout_title session_title">
          <div className="mainTitle">
            <div className="session_head_left">
              <Button
                className="icon_grey_btn"
                icon={<CloseIcon />}
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
              <h3>Flower Power Session</h3>
              <div className="session_avatar">
                <Avatar.Group
                  className="avatarGroup"
                  size="small"
                  max={{
                    count: 8,
                    style: {
                      color: "#161616",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                      fontSize: "10px",
                      fontWeight: "500",
                    },
                  }}
                >
                  {sessionData?.participants?.map(
                    (e: {
                      id: string;
                      first_name: string;
                      last_name: string;
                      image: { url: string };
                    }) => (
                      <Fragment key={e?.id}>
                        {e?.image?.url ? (
                          <div className="participant_avatar">
                            <Avatar src={e?.image?.url} />
                          </div>
                        ) : (
                          <div className="participant_avatar">
                            <Avatar>
                              {getInitials(e?.first_name, e?.last_name)}
                            </Avatar>
                          </div>
                        )}
                      </Fragment>
                    )
                  )}
                </Avatar.Group>
              </div>
            </div>
          </div>
        </Card>
      )}
      <div className="centered_content">{steps[current].content}</div>
    </FullPageLayout>
  );
}
