import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Image, Spin } from "antd";
import { Fragment, useEffect } from "react";
import { getSessionDetailsById } from "../../organisms/CompanyDetails/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getInitials } from "../../config/constants";
import { ArrowIconRight } from "../CustomIcons";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const { onClose, title, sessionId, setSessionDetail } = props;
  const dispatch = useAppDispatch();
  const sessionDetail = useAppSelector(
    (state) => state?.companyDetails?.sessionDetail
  );

  const onCloseHandler = () => {
    onClose();
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionDetailsById(sessionId));
    }
  }, [sessionId]);

  console.log("sessionDetail", sessionDetail);

  return (
    <Spin indicator={antIcon} size="large" spinning={false}>
      <div className="eTableModal" style={{ paddingBottom: "24px" }}>
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={onCloseHandler}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>

        <div className="modal_content view_session_detail_popup">
          <Card className="session_card">
            <div className="detail_top">
              <div className="detail_heading">Flower Power session</div>
              <div className="detail_btn">
                <Button
                  onClick={() => {
                    setSessionDetail(false);
                    window.location.assign(
                      `/flower-session-details/${sessionId}`
                    );
                  }}
                >
                  Session history <ArrowIconRight />
                </Button>
              </div>
            </div>
            <div className="detail_info">
              {/* <div className="detail_info_div">
                <div className="info_label">Completed:</div>
                <div className="info_value">Jun 23, 2024, 2:24 PM</div>
              </div> */}
              <div className="detail_info_div">
                <div className="info_label">Participants:</div>
                <div className="info_value">
                  <Avatar.Group
                    className="avatarGroup"
                    size="small"
                    max={{
                      count: 8,
                      style: {
                        color: "#161616",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        fontSize: "10px",
                        fontWeight: "500",
                      },
                    }}
                  >
                    {sessionDetail?.participants &&
                    sessionDetail?.participants.length > 0 ? (
                      sessionDetail?.participants.map(
                        (e: {
                          id: string;
                          first_name: string;
                          last_name: string;
                          image: { url: string };
                        }) => (
                          <Fragment key={e?.id}>
                            {e?.image?.url ? (
                              <div className="participant_avatar">
                                <Avatar src={e?.image?.url} />
                              </div>
                            ) : (
                              <div className="participant_avatar">
                                <Avatar>
                                  {getInitials(e?.first_name, e?.last_name)}
                                </Avatar>
                              </div>
                            )}
                          </Fragment>
                        )
                      )
                    ) : (
                      <div className="participant_avatar">
                        <span> - </span>
                      </div>
                    )}
                  </Avatar.Group>
                </div>
              </div>
              <div className="detail_info_div">
                <div className="info_label">Facilitator:</div>
                <div className="info_value">
                  <div className="facilitator_image">
                    {sessionDetail?.facilitator?.image?.url ? (
                      <div className="participant_avatar">
                        <Avatar
                          src={sessionDetail?.facilitator?.image?.url}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    ) : (
                      <div className="participant_avatar">
                        <Avatar>
                          {getInitials(
                            sessionDetail?.facilitator?.first_name,
                            sessionDetail?.facilitator?.last_name
                          )}
                        </Avatar>
                      </div>
                    )}
                  </div>
                  <div className="facilitator_name">
                    {`${sessionDetail?.facilitator?.first_name ?? ""} ${
                      sessionDetail?.facilitator?.last_name
                    }`}
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <Card className="session_card">
            <div className="detail_top">
              <div className="detail_heading">Worksheet session</div>
              <div className="detail_btn">
                <Button
                  onClick={() => {
                    setSessionDetail(false);
                    window.location.assign(`/ws-session-details/${sessionId}`);
                  }}
                >
                  Session history <ArrowIconRight />
                </Button>
              </div>
            </div>
            <div className="detail_info">
              {/* <div className="detail_info_div">
                <div className="info_label">Completed:</div>
                <div className="info_value">Jun 23, 2024, 2:24 PM</div>
              </div> */}
              <div className="detail_info_div">
                <div className="info_label">Participants:</div>
                <div className="info_value">
                  <Avatar.Group
                    className="avatarGroup"
                    size="small"
                    max={{
                      count: 8,
                      style: {
                        color: "#161616",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        fontSize: "10px",
                        fontWeight: "500",
                      },
                    }}
                  >
                    {sessionDetail?.participants &&
                    sessionDetail?.participants.length > 0 ? (
                      sessionDetail?.participants.map(
                        (e: {
                          id: string;
                          first_name: string;
                          last_name: string;
                          image: { url: string };
                        }) => (
                          <Fragment key={e?.id}>
                            {e?.image?.url ? (
                              <div className="participant_avatar">
                                <Avatar src={e?.image?.url} />
                              </div>
                            ) : (
                              <div className="participant_avatar">
                                <Avatar>
                                  {getInitials(e?.first_name, e?.last_name)}
                                </Avatar>
                              </div>
                            )}
                          </Fragment>
                        )
                      )
                    ) : (
                      <div className="participant_avatar">
                        <span> - </span>
                      </div>
                    )}
                  </Avatar.Group>
                </div>
              </div>
              <div className="detail_info_div">
                <div className="info_label">Facilitator:</div>
                <div className="info_value">
                  <div className="facilitator_image">
                    {sessionDetail?.facilitator?.image?.url ? (
                      <div className="participant_avatar">
                        <Avatar
                          src={sessionDetail?.facilitator?.image?.url}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    ) : (
                      <div className="participant_avatar">
                        <Avatar>
                          {getInitials(
                            sessionDetail?.facilitator?.first_name,
                            sessionDetail?.facilitator?.last_name
                          )}
                        </Avatar>
                      </div>
                    )}
                  </div>
                  <div className="facilitator_name">
                    {`${sessionDetail?.facilitator?.first_name ?? ""} ${
                      sessionDetail?.facilitator?.last_name
                    }`}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Spin>
  );
};
