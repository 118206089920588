import { Alert, Button, Card, Col, Image, Radio, Row } from "antd";
import {
  deleteRoleDescriptorById,
  updateIndividualPetalInfoByTeamId,
} from "../../../../../organisms/MyJob/ducks/services";
import { getFlowerDataBySessionID } from "../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import SessionFlowerFinalized from "../SessionFlowerFinalized";
import { SessionTypeResponse } from "../../../../../organisms/MyJob/ducks/types";
import { Id, Role } from "../../../../../molecules/KanbanBoard/types";
import RoleCardWithoutSort from "../../../../../molecules/KanbanBoard/Components/RoleCardWithoutSort";
import { useEffect, useState } from "react";
import { setRoleNames } from "../../../../../organisms/CompanyDetails/ducks/actions";
import { MenuSelectedIcon } from "../../../../../atoms/CustomIcons";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectFpSessionRole(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    petalTeams,
    sessionId,
    sessionData,
  } = props;
  const dispatch = useAppDispatch();
  const [roleToUpdate, setRoleToUpdate] = useState<any>(undefined);
  const roleNames = useAppSelector((state) => state?.companyDetails?.roleNames);

  const addMoreDescriptor = () => null;

  const deleteRole = (id: Id) => {
    deleteRoleDescriptorById(id).then(() => {
      updateApi();
    });
  };

  const updateRole = (id: Id, descriptor: string) => {
    const updatedLocal = activeData?.descriptors.map((role: Role) => {
      if (role.id !== id) {
        return role;
      }
      return { ...role, descriptor };
    });
    const findRoleById = activeData?.descriptors.find(
      (role: { id: string }) => role.id === id
    );
    const updatedRole = { ...findRoleById, descriptor };

    setRoleToUpdate(updatedRole);
    console.log(updatedLocal);
  };

  const updateApi = () => {
    dispatch(getFlowerDataBySessionID(sessionId));
  };

  const addNames = (num: number, id: string) => {
    if (roleNames?.[`seriousName${num}`] || roleNames?.[`funnyName${num}`]) {
      const payload = {
        funny_name: roleNames?.[`funnyName${num}`],
        serious_name: roleNames?.[`seriousName${num}`],
        petal_id: activeData?.id,
      };

      updateIndividualPetalInfoByTeamId(id, payload).then(() => {
        updateApi();
      });
    }
  };
  useEffect(() => {
    if (petalTeams?.length > 0) {
      petalTeams?.map((e: any, i: number) => {
        const seriousName = e?.contributions.find(
          (item1: any) => activeData?.id === item1.petal_id
        )?.serious_name;

        const funnyName = e?.contributions.find(
          (item1: any) => activeData?.id === item1.petal_id
        )?.funny_name;

        dispatch(setRoleNames(`seriousName${i + 1}`, seriousName));
        dispatch(setRoleNames(`funnyName${i + 1}`, funnyName));
      });
    }
  }, [petalTeams]);

  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <SessionFlowerFinalized
              data={data}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setCurrent={setCurrent}
              activeData={activeData}
              petalTeams={petalTeams}
            />
            <div className="flower_position">
              <div className="flew_wrapper">
                <h4>{sessionData?.job?.name}</h4>
                <p>
                  {sessionData?.c_person?.first_name ?? ""}{" "}
                  {sessionData?.c_person?.last_name ?? ""}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <Alert
            message="This activity is completed."
            icon={<MenuSelectedIcon />}
            type="success"
            showIcon
            style={{ marginBottom: 16, border: 0 }}
          />
          <h4>Select the best role names</h4>
          <p>
            With the entire group, present and debate each role name and select
            the one you agree is the best.
          </p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              Role
            </div>
            <div className="inputRadio">
              <Radio.Group
                name="radiogroup"
                defaultValue={
                  activeData?.finalized_name ? activeData?.finalized_name : ""
                }
                disabled
              >
                {petalTeams?.map((e: any, i: number) => (
                  <div className="best_role_wrapper">
                    <div className="finalized_div">
                      {i === 0 && (
                        <label className="label"> Serious name: </label>
                      )}
                      <div className="best_roles_name">
                        <Radio
                          value={
                            e?.contributions.find(
                              (item1: any) => activeData?.id === item1.petal_id
                            )?.serious_name ?? null
                          }
                          disabled
                        >
                          <div className="radio_uncheck">
                            <Image
                              src="/images/session/unchecked.svg"
                              preview={false}
                              width={20}
                              height={20}
                            />
                          </div>
                          <div className="radio_check">
                            <Image
                              src="/images/session/checked-filled.svg"
                              preview={false}
                              width={20}
                              height={20}
                            />
                          </div>
                        </Radio>
                        <div className="role_input">
                          <input
                            disabled
                            className="ant-input ant-input-outlined formControl"
                            placeholder="Type a serious name here"
                            value={roleNames?.[`seriousName${i + 1}`] ?? ""}
                            onBlur={() => addNames(i + 1, e?.id)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="finalized_div">
                      {i === 0 && (
                        <label className="label"> Funny name: </label>
                      )}
                      <div className="best_roles_name">
                        <Radio
                          value={
                            e?.contributions.find(
                              (item: any) => activeData?.id === item?.petal_id
                            )?.funny_name ?? null
                          }
                          disabled
                        >
                          <div className="radio_uncheck">
                            <Image
                              src="/images/session/unchecked.svg"
                              preview={false}
                              width={20}
                              height={20}
                            />
                          </div>
                          <div className="radio_check">
                            <Image
                              src="/images/session/checked-filled.svg"
                              preview={false}
                              width={20}
                              height={20}
                            />
                          </div>
                        </Radio>
                        <div className="role_input">
                          <input
                            disabled
                            className="ant-input ant-input-outlined formControl"
                            placeholder="Type a funny name here"
                            value={roleNames?.[`funnyName${i + 1}`] ?? ""}
                            onBlur={() => addNames(i + 1, e?.id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Radio.Group>
            </div>

            <div className="role_description">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <h4> Role descriptors: </h4>
                <Button disabled type="text">
                  + Add More
                </Button>
              </div>
              {activeData?.descriptors?.map((role: Role) => (
                <RoleCardWithoutSort
                  key={role.id}
                  role={role}
                  deleteRole={deleteRole}
                  updateRole={updateRole}
                  roleToUpdate={roleToUpdate}
                  updateApi={updateApi}
                  petalsSession={data}
                  oldPetalId={activeData?.id}
                  createRole={addMoreDescriptor}
                  sessionDetails={true}
                />
              ))}
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous role
            </Button>
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={
                +activeTab === data?.length || !activeData?.finalized_name
              }
              onClick={() => {
                setActiveTab((+activeTab + 1).toString());
              }}
            >
              Next role
            </Button>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() =>
                  petalTeams?.length > 1 ? setCurrent(5) : setCurrent(6)
                }
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(8)}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
