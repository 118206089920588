import { Button, Card, Col, Row, Input, Image } from "antd";
import {
  addRoleDescriptor,
  completeSessionById,
  deleteRoleDescriptorById,
  updatePetalById,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import SessionFlowerFinalized from "../SessionFlowerFinalized";
import { useAppDispatch } from "../../../../../../../config/hooks";
import { getFlowerDataBySessionID } from "../../../../../../../organisms/MyJob/ducks/actions";
import { useEffect, useState } from "react";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";
import { generateAIDescription } from "../../../../../../../organisms/CompanyDetails/ducks/services";
import { Id, Role } from "../../../../../../../molecules/KanbanBoard/types";
import RoleCardWithoutSort from "../../../../../../../molecules/KanbanBoard/Components/RoleCardWithoutSort";
import { AxiosError } from "axios";
import ErrorMessage from "../../../../../../Toasts/ErrorMessage";

const { TextArea } = Input;

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  petalData: any;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectFpSessionStatement(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    petalTeams,
    sessionId,
    sessionData,
    setLoading,
  } = props;
  // const dispatch = useAppDispatch();
  console.log("activeData", activeData);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [roleToUpdate, setRoleToUpdate] = useState<any>(undefined);

  const setPurposeStatement = () => {
    const payload = {
      purpose_statement: value,
    };
    updatePetalById(activeData?.id, payload).then(() => {
      dispatch(getFlowerDataBySessionID(sessionId));

      if (+activeTab === data?.length) {
        setEnableSubmit(true);
      }
    });
  };

  console.log("enableSubmit", enableSubmit);

  const CompleteSession = () => {
    setLoading(true);
    completeSessionById(sessionId).then(() => {
      const payloadScreen = {
        last_session_screen: 11,
      };
      updateSessionById(payloadScreen, sessionId).then(() => {
        setLoading(false);
        setCurrent(11);
      });
    });
  };

  const setPurposeStatementText = () => {
    setLoading(true);
    const payload = {
      name: activeData?.finalized_name,
      type: "role",
    };
    generateAIDescription(payload)
      .then((res) => {
        setValue(res?.data?.description);

        const payload = {
          purpose_statement: res?.data?.description,
        };
        updatePetalById(activeData?.id, payload).then(() => {
          dispatch(getFlowerDataBySessionID(sessionId));

          setLoading(false);
          if (+activeTab === data?.length) {
            setEnableSubmit(true);
          }
        });
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (activeData?.purpose_statement) {
      setValue(activeData?.purpose_statement);
    }
  }, [activeData]);

  const addMoreDescriptor = () => {
    const payload = {
      session_id: sessionId,
      descriptor: "descriptor",
      index: activeData?.descriptors?.length + 1,
      petal_id: activeData?.id,
    };

    addRoleDescriptor(payload).then(() => {
      updateApi();
    });
  };

  const deleteRole = (id: Id) => {
    deleteRoleDescriptorById(id).then(() => {
      updateApi();
    });
  };

  const updateRole = (id: Id, descriptor: string) => {
    const updatedLocal = activeData?.descriptors.map((role: Role) => {
      if (role.id !== id) {
        return role;
      }
      return { ...role, descriptor };
    });
    const findRoleById = activeData?.descriptors.find(
      (role: { id: string }) => role.id === id
    );
    const updatedRole = { ...findRoleById, descriptor };

    setRoleToUpdate(updatedRole);
    console.log(updatedLocal);
  };

  const updateApi = () => {
    dispatch(getFlowerDataBySessionID(sessionId));
  };

  const findEmptyStatement = () => {
    if (!data || data?.length === 0) {
      // Handle missing data
      return true; // Disable button if data is missing
    }

    return data?.some(
      (
        name: { purpose_statement: string } // Use some() for efficiency
      ) => !name.purpose_statement
    );
  };
  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <SessionFlowerFinalized
              data={data}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setCurrent={setCurrent}
              activeData={activeData}
              petalTeams={petalTeams}
              completed={true}
            />
            <div className="flower_position">
              <div className="flew_wrapper">
                <h4>{sessionData?.job?.name}</h4>
                <p>
                  {sessionData?.c_person?.first_name ?? ""}{" "}
                  {sessionData?.c_person?.last_name ?? ""}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          <h4>Define role purpose statements</h4>
          <p>
            Ensure role purpose statements clearly and concisely cover a role's
            why, how, and what. Typically, 3 or so sentences.
          </p>
          <p>
            Often starts with "The purpose of role X is to achieve ABC by doing
            XYZ ", where XYZ could be the tasks within the petal.
          </p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              {activeData?.finalized_name}
            </div>
            <div className="purpose_session_area">
              <div className="textarea_top">
                <label>Purpose statement</label>
                <Button
                  className="generate_btn"
                  onClick={setPurposeStatementText}
                >
                  <Image
                    src="/images/MagicWand.svg"
                    alt="Generate with AI"
                    width={16}
                    height={16}
                    preview={false}
                  />
                  Generate with AI
                </Button>
              </div>
              <TextArea
                style={{ minHeight: 148 }}
                autoSize
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Enter the statement here or generate with AI"
                onBlur={() => setPurposeStatement()}
              />
            </div>
            <div className="role_description">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <h4> Role descriptors: </h4>
                <Button onClick={addMoreDescriptor} type="text">
                  + Add More
                </Button>
              </div>

              {activeData?.descriptors?.map((role: Role) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RoleCardWithoutSort
                    key={role.id}
                    role={role}
                    deleteRole={deleteRole}
                    updateRole={updateRole}
                    roleToUpdate={roleToUpdate}
                    updateApi={updateApi}
                    petalsSession={data}
                    oldPetalId={activeData?.id}
                    sessionId={sessionId}
                    createRole={addMoreDescriptor}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous role
            </Button>

            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={+activeTab === data?.length}
              onClick={() => setActiveTab((+activeTab + 1).toString())}
            >
              Next role
            </Button>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(9)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={CompleteSession}
                disabled={findEmptyStatement()}
              >
                Complete session
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
