import { Fragment } from "react/jsx-runtime";
import { RoleType } from "../../../../../organisms/MyJob/ducks/types";
import { flowerMapGen } from "../FlowerMapGen";

type PropsType = {
  data: RoleType[];
  activeTab?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  activeData?: RoleType;
  petalTeams: any;
};

export default function SessionFlower(props: PropsType) {
  const { data, activeTab, petalTeams, setActiveTab } = props;

  return (
    <div className={`flower flowerReady ${flowerMapGen[data.length].class}`}>
      {data?.map((e: any, i: number) => (
        <Fragment key={i + 1}>
          <div
            className={`leaf leaf-${i + 1} ${
              activeTab ? parseInt(activeTab) === i + 1 && "active" : ""
            }`}
            onClick={() =>
              setActiveTab ? setActiveTab((i + 1).toString()) : null
            }
          >
            <div className="petal_svg">
              {flowerMapGen[data.length].petalShape}
            </div>
            <div className="leaf_text">
              <div className="leaf_detail">
                <h4>
                  {petalTeams?.length > 0 &&
                  petalTeams[0]?.contributions?.length > 0 &&
                  petalTeams[0]?.contributions?.find(
                    (item1: any) => e.id === item1.petal_id
                  )?.serious_name ? (
                    `S: ${
                      petalTeams[0]?.contributions?.find(
                        (item1: any) => e.id === item1.petal_id
                      )?.serious_name
                    }`
                  ) : (
                    <span style={{ opacity: 0.5 }}>Role names...</span>
                  )}
                </h4>
                <h4>
                  {petalTeams?.length > 0 &&
                  petalTeams[0]?.contributions?.length > 0 &&
                  petalTeams[0]?.contributions?.find(
                    (item1: any) => e.id === item1.petal_id
                  )?.funny_name
                    ? `F: ${
                        petalTeams[0]?.contributions?.find(
                          (item1: any) => e.id === item1.petal_id
                        )?.funny_name
                      }`
                    : ""}
                </h4>
              </div>
              <div className="leaf_number">{i + 1}</div>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
