import { useCallback, useEffect, useState } from "react";
import { Button, Layout, Image, Spin, Input, Dropdown, Avatar } from "antd";
import type { MenuProps } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getUserDetails } from "../../organisms/MyAccount/ducks/actions";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { SearchIcon } from "../../atoms/CustomIcons";
import { getGlobalSearchFields } from "../../organisms/CompanyDetails/ducks/services";
import { getInitials } from "../../config/constants";

const { Header, Content } = Layout;
const antIcon = <Loading3QuartersOutlined spin />;

type dashboardProps = {
  loading?: boolean;
  children?: React.ReactNode;
};

const DashboardTemplate = (props: dashboardProps) => {
  const { loading, children } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const [searchData, setSearchData] = useState<null | { data: [] }>({
    data: [],
  });
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);

  const logoutHandler = () => {
    localStorage.removeItem("@fpToken");
    window.location.assign("/login");
  };

  const itemsStatus: MenuProps["items"] = [
    {
      label: <Link to="/my-account"> My account details </Link>,
      key: "1",
    },
    {
      label: <Link to="/change-password"> Change password </Link>,
      key: "2",
    },
    {
      label: (
        <Button
          type="text"
          className="no_border"
          onClick={() => logoutHandler()}
        >
          Logout
        </Button>
      ),
      key: "3",
    },
  ];

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const getSelectedMenuItemKey = () => {
    if (
      location.pathname.includes("company-detail") &&
      accountData?.type === "SUPER_ADMIN"
    ) {
      return "companies";
    }

    if (
      location.pathname.includes("job-detail") &&
      accountData?.type === "SUPER_ADMIN"
    ) {
      return "companies";
    }

    if (
      location.pathname.includes("job-detail") &&
      accountData?.type === "COMPANY_ADMIN"
    ) {
      return "company-detail";
    }

    if (
      location.pathname.includes("my-job") &&
      accountData?.type === "COMPANY_ADMIN"
    ) {
      return "my-job";
    }

    return location?.pathname?.split("/")[1]; // Extract the first path segment
  };

  const currentPath = getSelectedMenuItemKey();

  const handleSearch = useCallback(
    debounce((value) => {
      if (value) {
        const payload = {
          search: value,
          company_id:
            accountData?.type === "SUPER_ADMIN"
              ? undefined
              : accountData?.company_id,
          offset: 0,
          limit: 10,
        };
        getGlobalSearchFields(payload).then((res) => {
          if (res?.data) {
            setSearchData(res?.data);
          }
        });
      }
    }, 1000),
    []
  );

  const handleChange = (event: { target: { value: any } }) => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
    setSearchData(null);
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Layout className="main_layout">
        <Header style={{ display: "flex", alignItems: "center" }}>
          <Link to="/" className="logo_area">
            <Image src="/images/login/fp_logo.svg" preview={false} />{" "}
          </Link>

          {accountData?.type === "SUPER_ADMIN" ? (
            <div className="top_header_links">
              <Button
                className={
                  currentPath === "companies"
                    ? "icon_dark_btn menu_active"
                    : "icon_white_btn"
                }
                onClick={() => navigate(`/companies`)}
              >
                Companies
              </Button>
              <Button
                className={
                  currentPath === "flower-power-team"
                    ? "icon_dark_btn menu_active"
                    : "icon_white_btn"
                }
                onClick={() => navigate(`/flower-power-team`)}
              >
                Flower Power team
              </Button>
            </div>
          ) : (
            <div className="top_header_links">
              <Button
                className={
                  currentPath === "my-job"
                    ? "icon_dark_btn menu_active"
                    : "icon_white_btn"
                }
                onClick={() =>
                  navigate(
                    `/my-job/${
                      accountData?.job_id ? accountData?.job_id : "no-job"
                    }/${accountData?.company_id}`
                  )
                }
              >
                My {companyData?.label_position === "Job" ? "job" : "seat"}
              </Button>
              <Button
                className={
                  currentPath === "company-detail"
                    ? "icon_dark_btn menu_active"
                    : "icon_white_btn"
                }
                onClick={() =>
                  navigate(`/company-detail/jobs/${accountData?.company_id}`)
                }
              >
                My company
              </Button>
            </div>
          )}

          <div style={{ display: "flex", gap: "16px", marginLeft: "auto" }}>
            <div className="search_filter">
              <Input
                placeholder={
                  accountData?.type === "SUPER_ADMIN"
                    ? "Search companies, jobs, roles, employees"
                    : `Search ${
                        companyData?.label_position === "Job" ? "jobs" : "seats"
                      }, roles, employees`
                }
                prefix={<SearchIcon />}
                onChange={handleChange}
                value={searchValue}
              />
              {searchData && searchData.data?.length > 0 && searchValue && (
                <div className="search_dropdown">
                  {searchData?.data?.map((item: any) => (
                    <div key={item?.id} className="search_item">
                      {item?.module === "Job" && (
                        <Button
                          onClick={() =>
                            navigate(
                              `/job-detail/${item?.entity_id}/${item?.company_id}`
                            )
                          }
                        >
                          <div className="label_text">
                            {item?.label}
                            <div className="label_module">{item?.module}</div>
                          </div>
                        </Button>
                      )}
                      {item?.module === "Company" && (
                        <Button
                          onClick={() =>
                            navigate(`/company-detail/jobs/${item?.company_id}`)
                          }
                        >
                          <div className="label_text">
                            {item?.label}
                            <div className="label_module">{item?.module}</div>
                          </div>
                        </Button>
                      )}
                      {item?.module === "User" && (
                        <Button
                          onClick={() =>
                            navigate(
                              `/employee-details/${item?.entity_id}/${item?.company_id}`
                            )
                          }
                        >
                          <div className="label_text">
                            {item?.label}
                            <div className="label_module">{item?.module}</div>
                          </div>
                        </Button>
                      )}
                      {item?.module === "Role" && (
                        <Button
                          onClick={() =>
                            navigate(
                              `/edit-role/${item?.entity_id}/${item?.company_id}`
                            )
                          }
                        >
                          <div className="label_text">
                            {item?.label}
                            <div className="label_module">{item?.module}</div>
                          </div>
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {searchValue && !searchData && (
                <div className="find_search no_data_find">
                  <Spin indicator={antIcon} size="large" spinning={true} />
                </div>
              )}
              {searchValue && searchData?.data?.length === 0 && (
                <div className="no_data_find">No results found.</div>
              )}
            </div>
            <div className="user_info">
              {/* <Dropdown overlay={notificationMenu} trigger={["click"]}>
                <div className="notificationButton">
                  <Image src="/images/login/bell_icon.svg" preview={false} />
                </div>
              </Dropdown> */}
              {/* <div className="notificationButton">
                <Image src="/images/login/bell_icon.svg" preview={false} />
              </div> */}
              <Dropdown
                menu={{ items: itemsStatus }}
                trigger={["click"]}
                placement="bottomLeft"
                overlayClassName="ft_dropdown"
              >
                <Button
                  type="text"
                  className="user_info_button"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="userName">
                    <span>
                      {`${accountData?.first_name} ${accountData?.last_name}`}
                    </span>
                    {accountData?.image?.url ? (
                      <Image
                        src={accountData?.image?.url}
                        alt="icon"
                        width={36}
                        height={36}
                        preview={false}
                      />
                    ) : (
                      <div className="table_with_image">
                        <Avatar
                          shape="square"
                          size={36}
                          style={{ marginRight: 0 }}
                        >
                          {getInitials(
                            accountData?.first_name,
                            accountData?.last_name
                          )}
                        </Avatar>
                      </div>
                    )}
                  </div>
                </Button>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content className="content_area">{children}</Content>
      </Layout>
    </Spin>
  );
};

export default DashboardTemplate;
