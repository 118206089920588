import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import KanbanBoard from "../../../../molecules/KanbanBoard";
import { Column, Role } from "../../../../molecules/KanbanBoard/types";
import { getPetalsWithRoleDescriptorsBySessionId } from "../../../../organisms/MyJob/ducks/actions";
import { SessionTypeResponse } from "../../../../organisms/MyJob/ducks/types";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionId: string;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step3(props: PropsType) {
  const { setCurrent, sessionId, sessionData } = props;
  const [roles, setRoles] = useState<Role[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const dispatch = useAppDispatch();
  const roleData = useAppSelector((state) => state?.jobDetail?.roleDataSession);

  const transformData = () => {
    setCurrent(4);
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(getPetalsWithRoleDescriptorsBySessionId(sessionId));
    }
  }, [dispatch, sessionId]);

  return (
    <KanbanBoard
      roleData={roleData}
      setCurrent={setCurrent}
      transformData={transformData}
      roles={roles}
      setRoles={setRoles}
      columns={columns}
      setColumns={setColumns}
      sessionId={sessionId}
      sessionData={sessionData}
      sessionDetails={true}
    />
  );
}
