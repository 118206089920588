import { useEffect, useState } from "react";
import { Row, Col, Button, Card, Form } from "antd";
import { useForm } from "react-hook-form";
import UploadPhoto from "../../../../molecules/FormItems/UploadPhoto";
import InputField from "../../../../molecules/FormItems/InputField";
import { uploadUserImage } from "../../../../organisms/MyAccount/ducks/services";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getUserDetails } from "../../../../organisms/MyAccount/ducks/actions";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { AxiosError } from "axios";
import { CloseIcon, DropdownIcon } from "../../../../atoms/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
import SelectField from "../../../../molecules/FormItems/SelectField";
import { updateEmployeeById } from "../../ducks/services";
import { getEmployeeDetails } from "../../../SystemSettings/ducks/actions";
import { onImageDelete } from "../../../ChangePassword/ducks/services";
import { getUserFilters } from "../../ducks/actions";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  user_type: string;
  job: string;
};

export default function EditEmployee() {
  const url = useLocation();
  const employeeId = url?.pathname.split("/")[2];
  const companyId = url?.pathname.split("/")[3];
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const employeeFiltersOptions = useAppSelector(
    (state) => state?.companyDetails?.employeeFiltersOptions
  );

  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const userDetail = useAppSelector((state) => state?.system?.userDetail);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>();

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      first_name: val?.first_name,
      last_name: val?.last_name,
      email: val?.email,
      type: val?.user_type,
      job_id: val?.job ?? undefined,
      companyId: companyId,
    };
    updateEmployeeById(payload, employeeId)
      .then(() => {
        setLoading(false);
        dispatch(getUserDetails());
        SuccessMessage("Profile updated.");

        navigate(-1);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    uploadUserImage({
      owner_id: employeeId,
      image: checklistFile?.uid ? checklistFile : undefined,
      image_type: "user_image",
    })
      .then((res) => {
        if (res) {
          SuccessMessage("Image changed.");
          setLoading(false);
          setUploadModal(false);
          dispatch(getEmployeeDetails(employeeId));
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const userTypeDropDown = [
    {
      value: "INDIVIDUAL_CONTRIBUTOR",
      label: "Individual Contributor",
      desc: (
        <>
          Participates in the Flower Power sessions and has a view access to the
          company's organizational structure.
        </>
      ),
    },
    {
      value: "COMPANY_ADMIN",
      label: "Company Admin",
      desc: "Manages the company and its employees. Can facilitate and participate in the Flower Power sessions",
    },
  ];

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeDetails(employeeId));
    }
  }, [employeeId, dispatch]);

  useEffect(() => {
    if (userDetail) {
      setValue("first_name", userDetail?.first_name);
      setValue("last_name", userDetail?.last_name);
      setValue("email", userDetail?.email);
      setValue("user_type", userDetail?.type);
      if (userDetail?.job_id) {
        setValue("job", userDetail?.job_id);
      }
    }
  }, [userDetail]);

  function callUserDetailsAPI() {
    dispatch(getEmployeeDetails(employeeId));
  }

  useEffect(() => {
    if (companyId) {
      dispatch(getUserFilters(companyId));
    }
  }, [dispatch, companyId]);

  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <h3>Employee details</h3>
        </div>
      </Card>
      <Card className="fp_layout_content" style={{ height: "100vh" }}>
        <div className="cardContent">
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4
                      style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      User photo
                    </h4>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <UploadPhoto
                        uploadAvatar={
                          userDetail?.image?.url
                            ? userDetail?.image?.url
                            : "/images/upload-avatar.svg"
                        }
                        imageUploaded={userDetail?.image?.url}
                        uploadedImageURL={
                          userDetail?.image?.url
                            ? userDetail?.image?.url
                            : undefined
                        }
                        width={88}
                        height={88}
                        saveUpload={saveUpload}
                        loading={loading}
                        uploadModal={uploadModal}
                        setUploadModal={setUploadModal}
                        onImageDelete={() => {
                          onImageDelete(
                            setLoading,
                            SuccessMessage,
                            callUserDetailsAPI,
                            ErrorMessage,
                            userDetail?.image?.id
                              ? userDetail?.image?.id
                              : undefined
                          );
                        }}
                        previewImage={previewImage}
                        checklistFile={checklistFile}
                        modalPreviewImage={modalPreviewImage}
                        setChecklistFile={setChecklistFile}
                        setPreviewImage={setPreviewImage}
                        setModalPreviewImage={setModalPreviewImage}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 265px">
                    <h4>
                      First name <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="first_name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Type first name",
                      }}
                      isRequired
                      rules={{
                        required: "First name is required.",
                      }}
                      validate={errors.first_name && "error"}
                      validMessage={
                        errors.first_name && errors.first_name.message
                      }
                    />
                  </Col>
                  <Col flex="0 0 265px">
                    <h4>
                      Last name <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="last_name"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Type last name",
                      }}
                      isRequired
                      rules={{
                        required: "Last name is required.",
                      }}
                      validate={errors.last_name && "error"}
                      validMessage={
                        errors.last_name && errors.last_name.message
                      }
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <h4>
                      Email address <span className="red_color">*</span>
                    </h4>
                    <InputField
                      fieldName="email"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Type email address",
                      }}
                      isRequired
                      rules={{
                        required: "Email is required.",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Email is required.",
                        },
                      }}
                      validate={errors.email && "error"}
                      validMessage={errors.email && errors.email.message}
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <SelectField
                      fieldName="job"
                      label="Job title"
                      control={control}
                      selectOption={employeeFiltersOptions?.job}
                      className="fp_select"
                      iProps={{
                        placeholder: "Select job",
                        suffixIcon: <DropdownIcon />,
                      }}
                      tooltipTitle="The position at your company that the employee occupies."
                    />
                  </Col>
                  <Col flex="0 0 533px" style={{ marginBottom: "16px" }}>
                    <SelectField
                      fieldName="user_type"
                      label="Access level"
                      control={control}
                      selectOption={userTypeDropDown}
                      className="fp_select"
                      iProps={{
                        placeholder: "Select access level",
                        suffixIcon: <DropdownIcon />,
                      }}
                      isRequired
                      rules={{
                        required: "Access level is required.",
                      }}
                      tooltipTitle="This defines what the employee can do within the system."
                    />
                  </Col>
                  <Col className="fp_footer">
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                        disabled={!isDirty || !isValid}
                      >
                        Save changes
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
