import { useEffect, useState } from "react";
import { Button, Drawer, Image, Tabs, TabsProps } from "antd";
import GuideSkills from "./GuideSkills";
import GuideKeyResults from "./GuideKeyResults";
import GuideTechnologies from "./GuideTechnologies";
import GuideTeams from "./GuideTeams";
import GuideMeetings from "./GuideMeetings";
import GuideWorkflows from "./GuideWorkflows";

type PropsType = {
  openWorksheet: boolean;
  setOpenWorksheet: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultTabSelected?: React.Dispatch<React.SetStateAction<string>>;
  defaultTabSelected?: string;
};

const SettingsGuide = (props: PropsType) => {
  const {
    openWorksheet,
    setOpenWorksheet,
    defaultTabSelected,
    setDefaultTabSelected,
  } = props;
  const [activeKey, setAvtiveKey] = useState("guide_departments");
  const showDrawer = () => {
    setOpenWorksheet(true);
  };

  const onClose = () => {
    setOpenWorksheet(false);
    setDefaultTabSelected && setDefaultTabSelected("");
  };

  const items: TabsProps["items"] = [
    {
      key: "guide_key_results",
      label: "Key Results",
      children: <GuideKeyResults />,
    },
    {
      key: "guide_workflows",
      label: "Workflows",
      children: <GuideWorkflows />,
    },
    {
      key: "guide_skills",
      label: "Skills",
      children: <GuideSkills />,
    },
    {
      key: "guide_technologies",
      label: "Technologies",
      children: <GuideTechnologies />,
    },
    {
      key: "guide_teams",
      label: "Teams",
      children: <GuideTeams />,
    },
    {
      key: "guide_meetings",
      label: "Meetings",
      children: <GuideMeetings />,
    },
  ];

  const onChange = (key: string) => {
    setAvtiveKey(key);
  };

  useEffect(() => {
    if (defaultTabSelected) {
      setAvtiveKey(defaultTabSelected);
    }
  }, [defaultTabSelected]);

  return (
    <>
      <Button type="primary" onClick={showDrawer} className="drawerBtn">
        <Image src="/images/guide-icon.svg" preview={false} />
      </Button>

      <Drawer
        title="Company settings guide"
        placement="right"
        closable={true}
        onClose={onClose}
        open={openWorksheet}
        // getContainer={false}
        className="guide_drawer"
        maskClosable={false}
        mask={false}
      >
        <Tabs
          tabPosition="left"
          destroyInactiveTabPane
          onChange={onChange}
          activeKey={activeKey}
          items={items}
          className="drawer_tabs"
        />
      </Drawer>
    </>
  );
};

export default SettingsGuide;
