import { Alert, Button, Card, Col, Row } from "antd";
import {
  addRoleDescriptor,
  deleteRoleDescriptorById,
  updateSessionById,
} from "../../../../../../../organisms/MyJob/ducks/services";
import SessionFlowerFinalized from "../SessionFlowerFinalized";
import { SessionTypeResponse } from "../../../../../../../organisms/MyJob/ducks/types";
import { getFlowerDataBySessionID } from "../../../../../../../organisms/MyJob/ducks/actions";
import { useAppDispatch } from "../../../../../../../config/hooks";
import { Id, Role } from "../../../../../../../molecules/KanbanBoard/types";
import RoleCardWithoutSort from "../../../../../../../molecules/KanbanBoard/Components/RoleCardWithoutSort";
import { useState } from "react";
import { MenuSelectedIcon } from "../../../../../../CustomIcons";

type PropsType = {
  data: any;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: any;
  activeNumber: number;
  petalData: any;
  sessionId: string;
  petalTeams: any;
  sessionData: SessionTypeResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectFpSessionCategory(props: PropsType) {
  const {
    data,
    activeTab,
    setActiveTab,
    activeData,
    setCurrent,
    activeNumber,
    sessionId,
    petalTeams,
    sessionData,
    setLoading,
  } = props;
  const dispatch = useAppDispatch();
  const [roleToUpdate, setRoleToUpdate] = useState<any>(undefined);

  const submitSFNames = () => {
    setLoading(true);
    const payloadScreen = {
      last_session_screen: 10,
    };
    updateSessionById(payloadScreen, sessionId).then(() => {
      setLoading(false);
      setCurrent(10);
    });
  };

  const addMoreDescriptor = () => {
    const payload = {
      session_id: sessionId,
      descriptor: "descriptor",
      index: activeData?.descriptors?.length + 1,
      petal_id: activeData?.id,
    };

    addRoleDescriptor(payload).then(() => {
      updateApi();
    });
  };

  const deleteRole = (id: Id) => {
    deleteRoleDescriptorById(id).then(() => {
      updateApi();
    });
  };

  const updateRole = (id: Id, descriptor: string) => {
    const updatedLocal = activeData?.descriptors.map((role: Role) => {
      if (role.id !== id) {
        return role;
      }
      return { ...role, descriptor };
    });
    const findRoleById = activeData?.descriptors.find(
      (role: { id: string }) => role.id === id
    );
    const updatedRole = { ...findRoleById, descriptor };

    setRoleToUpdate(updatedRole);
    console.log(updatedLocal);
  };

  const updateApi = () => {
    dispatch(getFlowerDataBySessionID(sessionId));
  };

  console.log("sessionData", sessionData);
  return (
    <Row>
      <Col span={13}>
        <div className="session_flower finalizedFlower">
          <div
            className="flower_wrapper"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <SessionFlowerFinalized
              data={data}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setCurrent={setCurrent}
              activeData={activeData}
              petalTeams={petalTeams}
              categorized={true}
            />
            <div className="flower_position">
              <div className="flew_wrapper">
                <h4>{sessionData?.job?.name}</h4>
                <p>
                  {sessionData?.c_person?.first_name ?? ""}{" "}
                  {sessionData?.c_person?.last_name ?? ""}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span={11}>
        <Card className="session_fp_card fp_session_role session_inner_tab">
          {sessionData?.last_session_screen > 9 && (
            <Alert
              message="This activity is completed."
              icon={<MenuSelectedIcon />}
              type="success"
              showIcon
              style={{ marginBottom: 16, border: 0 }}
            />
          )}

          <h4> Categorize role descriptors </h4>
          <p>
            Select whether its a Responsibility or a Procedure/Task. Optional
            step.
          </p>
          <div className="content_section">
            <div className="active_state" style={{ marginBottom: "16px" }}>
              <h6 className={`tagNumber_${activeNumber}`}> {activeNumber} </h6>
              {activeData?.finalized_name}
            </div>

            <div className="role_description">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <h4> Role descriptors: </h4>
                <Button onClick={addMoreDescriptor} type="text">
                  + Add More
                </Button>
              </div>

              {activeData?.descriptors?.map((role: Role) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RoleCardWithoutSort
                    key={role.id}
                    role={role}
                    deleteRole={deleteRole}
                    updateRole={updateRole}
                    roleToUpdate={roleToUpdate}
                    updateApi={updateApi}
                    petalsSession={data}
                    oldPetalId={activeData?.id}
                    sessionId={sessionId}
                    createRole={addMoreDescriptor}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="move_buttons">
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={activeTab === "1"}
              onClick={() => {
                setActiveTab((+activeTab - 1).toString());
              }}
            >
              Previous role
            </Button>
            <Button
              htmlType="button"
              className="icon_grey_btn"
              type="primary"
              size="large"
              disabled={+activeTab === data?.length}
              onClick={() => {
                setActiveTab((+activeTab + 1).toString());
              }}
            >
              Next role
            </Button>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(8)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={() =>
                  sessionData?.last_session_screen > 9
                    ? setCurrent(10)
                    : submitSFNames()
                }
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
