import { Role } from "../../../molecules/KanbanBoard/types";
import * as action_types from "./constants";
import { JobDetailType, SessionTypeResponse } from "./types";

export type MyJob = {
  jobDetailData: {
    loading: boolean;
    detailData: null | JobDetailType;
  };
  participantsData: [];
  sessionData: {
    data: SessionTypeResponse;
    loading: boolean;
  };
  roleData: Role[];
  roleDataSession: Role[];
  sessionFlowerData: {
    petalTeams: [];
    sessionPetals: [];
  };
  petalData: any;
};

const initialState: MyJob = {
  participantsData: [],
  sessionData: {
    data: {
      company_id: "",
      facilitator_id: "",
      center_person: "",
      id: "",
      job_id: "",
      last_session_screen: 0,
      link: "",
      facilitator: {
        first_name: "",
        last_name: "",
        image: {
          url: "",
          id: "",
        },
        job: {
          name: "",
          id: "",
        },
      },
      c_person: {
        first_name: "",
        last_name: "",
        image: {
          url: "",
          id: "",
        },
        job: {
          name: "",
          id: "",
        },
      },
      participants: [
        {
          id: "",
          company_id: "",
          email: "",
          first_name: "",
          last_name: "",
          job_id: "",
          type: "",
          status: "",
          image: {
            url: "",
            id: "",
          },
          job: {
            name: "",
          },
        },
      ],
      job: {
        name: "",
        id: "",
      },
    },
    loading: true,
  },
  jobDetailData: {
    loading: true,
    detailData: null,
  },
  roleData: [],
  roleDataSession: [],
  sessionFlowerData: {
    petalTeams: [],
    sessionPetals: [],
  },
  petalData: {},
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.JOB_DETAILS:
      return {
        ...state,
        jobDetailData: {
          detailData: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.PARTICIPANTS_LIST:
      return { ...state, participantsData: data };

    case action_types.SESSION_PARTICIPANTS:
      return {
        ...state,
        sessionData: {
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.ROLE_DESCRIPTORS:
      return { ...state, roleData: data };

    case action_types.ROLE_DESCRIPTORS_BY_SESSION_ID:
      return { ...state, roleDataSession: data };

    case action_types.SESSION_FLOWER_DATA:
      return { ...state, sessionFlowerData: data };

    case action_types.PETALS_SESSION_DATA:
      return { ...state, petalData: data };

    default:
      return state;
  }
};
