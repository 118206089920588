import { Fragment } from "react/jsx-runtime";
import { RoleType } from "../../../../../organisms/MyJob/ducks/types";
import { flowerMapGen } from "../FlowerMapGen";

type PropsType = {
  data: RoleType[];
  activeTab?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  activeData?: RoleType;
  petalTeams: any;
  categorized?: boolean;
  completed?: boolean;
};

export default function SessionFlowerFinalized(props: PropsType) {
  const { data, activeTab, completed, setActiveTab } = props;

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  return (
    <div className={`flower ${flowerMapGen[data.length].class}`}>
      {data?.map((e: any, i: number) => (
        <Fragment key={i + 1}>
          <div
            className={`leaf leaf-${i + 1} ${
              activeTab ? parseInt(activeTab) === i + 1 && "active" : ""
            }`}
            onClick={() =>
              setActiveTab ? setActiveTab((i + 1).toString()) : null
            }
          >
            <div className="petal_svg">
              {flowerMapGen[data.length].petalShape}
            </div>
            <div className="leaf_text">
              <div className="leaf_detail">
                <h4>
                  {e?.finalized_name ? (
                    e?.finalized_name
                  ) : (
                    <span style={{ opacity: 0.5 }}>Role names...</span>
                  )}
                </h4>
                {/* {categorized && (
                  <p style={{ marginTop: 4 }}>
                    {e?.descriptors?.length > 0 &&
                      e?.descriptors[0]?.category &&
                      CATEGORIES[
                        e?.descriptors[0]?.category as keyof typeof CATEGORIES
                      ]}
                  </p>
                )} */}
                {e?.purpose_statement && completed && (
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      e?.purpose_statement as string
                    )}
                    className="leaf_paragraph"
                  />
                )}
              </div>
              <div className="leaf_number">{i + 1}</div>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
