import Lottie from "react-lottie";
import { Card, Button, Row, Col, Alert, Input } from "antd";
import { SessionTypeResponse } from "../../../../organisms/MyJob/ducks/types";
import * as animationData from "../Components/LottieAnimations/FP_Name_animation.json";
import { MenuSelectedIcon } from "../../../../atoms/CustomIcons";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../../config/constants";

const webUrl = BASE_URLS[ENVIRONMENT_MODE].REACT_WEB_URL;

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  sessionData: SessionTypeResponse;
  sessionId: string;
};

export default function Step2(props: PropsType) {
  const { setCurrent, sessionData, sessionId } = props;
  const generateListLink = `${webUrl}/fp-session/generate-list/${sessionId}`;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Row className="shareLink">
      <Col span={12} className="col_1">
        <Card className="fp_layout_content link_card copy_card">
          <div className="copy_link_content">
            <Alert
              message="This activity is completed."
              icon={<MenuSelectedIcon />}
              type="success"
              showIcon
              style={{ marginBottom: 16, border: 0 }}
              className="success_copy"
            />
            <div className="session2_content">
              <h2>Generate the list</h2>
              <p style={{ marginBottom: "10px" }}>
                Share the link below with each participant.
              </p>
              <p>
                Ask them to take 2 to 3 quiet minutes to create a list. Ask them
                to write down everything that{" "}
                <b>
                  {`${sessionData?.c_person?.first_name ?? ""} ${
                    sessionData?.c_person?.last_name ?? ""
                  }`}{" "}
                </b>
                is thinking about or doing, or should be thinking about or doing
                every second, minute, hour, day, week, month, year to fulfill
                the objective of their Job.
              </p>
              <div
                className="copyLinkText"
                style={{ position: "relative", marginTop: 24 }}
              >
                <Input
                  value={generateListLink}
                  placeholder={generateListLink}
                />
                <Button
                  className="copyLinkBtn"
                  disabled={sessionData?.last_session_screen > 2}
                >
                  <img src="/images/attachment_icon.svg" alt="" /> Copy link
                </Button>
              </div>
            </div>
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(0)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(2)}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={12} className="col_2">
        <div className="step2_role">
          <Lottie options={defaultOptions} width={510} />
        </div>
      </Col>
    </Row>
  );
}
