import { useEffect, useRef, useState } from "react";
import { Button, Card, Divider, Input, InputRef, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getRolesFilters } from "../../../../organisms/CompanyDetails/ducks/actions";
import { createJobByCompanyId } from "../../../../organisms/CompanyDetails/ducks/services";
import { AxiosError, AxiosResponse } from "axios";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import { CloseIcon, SelectArrowIcon } from "../../../../atoms/CustomIcons";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyDetails } from "../../../CompanySettings/ducks/actions";
import { PlusOutlined } from "@ant-design/icons";
import { createSession } from "../../../MyJob/ducks/services";
// import { PlusOutlined } from "@ant-design/icons";

export default () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const url = useLocation();
  const companyId = url?.pathname.split("/")[2];
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);
  const navigate = useNavigate();
  const [items, setItems] = useState<{ label: string; value: string }[]>([]);
  const [jobName, setJobName] = useState("");
  const [name, setName] = useState("");
  const [jobValue, setJobValue] = useState<{ label: string; value: string }>({
    label: "",
    value: "",
  });
  const inputRef = useRef<InputRef>(null);
  const prevJobList = useAppSelector(
    (state) => state?.companyDetails?.rolesFiltersOptions
  );

  useEffect(() => {
    if (companyId) {
      dispatch(getRolesFilters(companyId));
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  const onSubmit = () => {
    setLoading(true);
    if (jobValue?.value) {
      const payload = {
        job_id: jobValue?.value,
        company_id: companyId,
        jobHolders: [],
      };

      createSession(payload)
        .then((res: AxiosResponse) => {
          if (res && res?.data?.id) {
            setLoading(false);
            window.location.assign(`/flower-session/${res?.data?.id}`);
          }
        })
        .catch((e: unknown) => {
          if (e instanceof AxiosError) {
            ErrorMessage(
              e?.response?.data?.error?.message ?? "Something went wrong"
            );
            setLoading(false);
          } else {
            ErrorMessage("Something went wrong");
            setLoading(false);
          }
        });
    } else {
      {
        const payload = {
          jobName:
            prevJobList?.jobWithoutSessionSet?.length > 0
              ? jobValue?.label
              : name,
          companyId: companyId,
          jobHolders: [],
        };
        createJobByCompanyId(payload)
          .then((jobRes: AxiosResponse) => {
            setLoading(false);

            const sessionPayload = {
              job_id: jobRes?.data?.id,
              company_id: companyId,
            };

            createSession(sessionPayload)
              .then((res: AxiosResponse) => {
                if (res && res?.data?.id) {
                  setLoading(false);
                  window.location.assign(`/flower-session/${res?.data?.id}`);
                }
              })
              .catch((e: unknown) => {
                if (e instanceof AxiosError) {
                  ErrorMessage(
                    e?.response?.data?.error?.message ?? "Something went wrong"
                  );
                  setLoading(false);
                } else {
                  ErrorMessage("Something went wrong");
                  setLoading(false);
                }
              });

            console.log("res?.data?.id", jobRes?.data);

            // SuccessMessage(`${companyData?.label_position} created.`);
            // navigate(`/company-detail/jobs/${companyId}`);
          })
          .catch((e: unknown) => {
            if (e instanceof AxiosError) {
              setLoading(false);
              ErrorMessage(
                e?.response?.data?.error?.message ?? "Something went wrong"
              );
            } else {
              setLoading(false);
              ErrorMessage("Something went wrong");
            }
          });
      }
    }
  };

  console.log("name", name, jobName);

  useEffect(() => {
    if (prevJobList?.jobWithoutSessionSet?.length > 0) {
      setItems(prevJobList?.jobWithoutSessionSet);
    }
  }, [prevJobList?.jobWithoutSessionSet]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobName(event.target.value);
    setName("");
  };
  const onNewName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setJobName("");
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (jobName?.length > 0) {
      setItems([...items, { label: jobName, value: "" }]);
      setJobName("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  const handleChange = (_value: string, option: any) => {
    setJobValue(option);
  };
  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(`/company-detail/jobs/${companyId}`)}
          >
            Cancel session
          </Button>
          <h3>Flower Power session</h3>
        </div>
      </Card>
      <Card className="fp_layout_content session_card">
        <div className="cardContent">
          <div
            className="select_individual_head"
            style={{ flexDirection: "column" }}
          >
            <div className="individual_left ">
              <h4>
                Specify the {companyData?.label_position?.toLowerCase()} to
                perform the session for
              </h4>
              <p>
                {prevJobList?.jobWithoutSessionSet?.length > 0 ? (
                  <>
                    Enter the name of the&nbsp;
                    {companyData?.label_position?.toLowerCase()} or select from
                    the list if it already exists.
                  </>
                ) : (
                  <>
                    Enter the name of the&nbsp;
                    {companyData?.label_position?.toLowerCase()} to the field
                    below.
                  </>
                )}
              </p>
            </div>
            <div style={{ marginTop: 24, width: "100%" }}>
              {prevJobList?.jobWithoutSessionSet?.length > 0 ? (
                <Select
                  style={{ width: "100%" }}
                  suffixIcon={<SelectArrowIcon />}
                  showSearch
                  placeholder={`Select or add a ${companyData?.label_position?.toLowerCase()}`}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <div style={{ padding: "0 8px 4px", width: "100%" }}>
                        <Input
                          placeholder={`Type the ${companyData?.label_position?.toLowerCase()} name`}
                          ref={inputRef}
                          value={jobName}
                          onChange={onNameChange}
                          onKeyDown={(e) => e.stopPropagation()}
                          className="formControl"
                          style={{
                            width: "calc(100% - 150px)",
                            marginRight: 20,
                          }}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addItem}
                        >
                          Add {companyData?.label_position?.toLowerCase()}
                        </Button>
                      </div>
                    </>
                  )}
                  options={items}
                  onChange={handleChange}
                />
              ) : (
                <Input
                  placeholder={`Type the ${companyData?.label_position?.toLowerCase()} name`}
                  ref={inputRef}
                  value={name}
                  onChange={onNewName}
                  className="formControl"
                />
              )}
            </div>
          </div>

          <div className="participants_card">
            <Button
              htmlType="button"
              className="icon_dark_btn"
              type="primary"
              size="large"
              onClick={onSubmit}
              disabled={name?.length === 0 && jobValue?.label?.length === 0}
            >
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </FullPageLayout>
  );
};
