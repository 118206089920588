import { Button, Col, Row, Form, Alert, Card, Empty } from "antd";
import { useForm } from "react-hook-form";
import SelectField from "../../../../molecules/FormItems/SelectField";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getJobFilters } from "../../../../organisms/CompanyDetails/ducks/actions";
import { createJobByCompanyId } from "../../../../organisms/CompanyDetails/ducks/services";
import { AxiosError } from "axios";
import InputField from "../../../../molecules/FormItems/InputField";
import SuccessMessage from "../../../../atoms/Toasts/SuccessMessage";
import ErrorMessage from "../../../../atoms/Toasts/ErrorMessage";
import { CloseIcon, DropdownIcon } from "../../../../atoms/CustomIcons";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyDetails } from "../../../CompanySettings/ducks/actions";
import SelectFieldWithButton from "../../../../molecules/FormItems/SelectFieldWithButton";
import { Popup } from "../../../../atoms/Popup";
import CreateEmployee from "../../../../atoms/CreateEmployee";

export default () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [createEmployee, setCreateEmployee] = useState(false);
  const url = useLocation();
  const companyId = url?.pathname.split("/")[2];
  const [otherJobsHolder, setOtherJobsHolder] = useState([]);

  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );
  const companyData = useAppSelector((state) => state?.settings?.companyDetail);
  const navigate = useNavigate();

  type FormValues = {
    jobName: string;
    jobHolders: string[];
    department: string;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    clearErrors,
  } = useForm<FormValues>();
  const selectedValues = watch("jobHolders");
  useEffect(() => {
    if (companyId) {
      dispatch(getJobFilters(companyId));
      dispatch(getCompanyDetails(companyId));
    }
  }, [dispatch, companyId]);

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      jobName: val?.jobName,
      jobHolders: val?.jobHolders ?? [],
      department: val?.department ?? "",
      companyId: companyId,
    };
    createJobByCompanyId(payload)
      .then(() => {
        setLoading(false);
        SuccessMessage(`${companyData?.label_position} created.`);
        navigate(`/company-detail/jobs/${companyId}`);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const checkHoldersJob = (val: { value: string }[]) => {
    const jobHolders = jobsFilterOptions?.employee?.filter(
      (emp: { value: string; job_id?: string }) =>
        val?.some((v) => v?.value === emp?.value && emp?.job_id)
    );
    setOtherJobsHolder(jobHolders);
  };
  const updateApi = (newEmp: string) => {
    let updatedValue = [];
    if (selectedValues) {
      updatedValue = [...selectedValues, newEmp];
    } else {
      updatedValue = [newEmp];
    }

    clearErrors("jobHolders");
    setValue("jobHolders", updatedValue);
  };

  const createJob = {
    visibility: createEmployee,
    class: "upload_photo_modal",
    content: (
      <CreateEmployee
        title="Create employee"
        onClose={() => setCreateEmployee(false)}
        btnText={`Create ${companyData?.label_position?.toLowerCase()}`}
        companyId={companyId}
        updateApi={updateApi}
        labelPosition={companyData?.label_position}
      />
    ),
    width: 600,
    onCancel: () => setCreateEmployee(false),
  };

  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <h3>
            {companyData?.label_position === "Job"
              ? "Create Job"
              : "Create Seat"}
          </h3>
        </div>
      </Card>
      <Card className="fp_layout_content">
        <div className="cardContent">
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <div className="modal_content">
              <Row gutter={16}>
                <Col flex="0 0 100%">
                  <InputField
                    fieldName="jobName"
                    label={`${companyData?.label_position} title`}
                    control={control}
                    initValue=""
                    iProps={{
                      placeholder: `Type ${companyData?.label_position?.toLowerCase()} title`,
                    }}
                    isRequired
                    rules={{
                      required:
                        companyData?.label_position + " title is required.",
                    }}
                    validate={errors.jobName && "error"}
                    validMessage={errors.jobName && errors.jobName.message}
                    tooltipTitle="The position title at your company for which you are conducting the Flower Power session."
                  />
                </Col>
                <Col flex="0 0 100%">
                  <SelectField
                    fieldName="department"
                    label="Department"
                    control={control}
                    selectOption={jobsFilterOptions?.department}
                    className="fp_select"
                    iProps={{
                      placeholder: "Select department",
                      suffixIcon: <DropdownIcon />,
                      notFoundContent: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="There are no departments added yet."
                        />
                      ),
                    }}
                    tooltipTitle="A division in your company which the job belongs to."
                  />
                </Col>
                <Col flex="0 0 100%">
                  <SelectFieldWithButton
                    fieldName="jobHolders"
                    label={`${companyData?.label_position} holders`}
                    control={control}
                    selectOption={jobsFilterOptions?.employee}
                    className="fp_select"
                    mode="multiple"
                    iProps={{
                      placeholder: "Select employees",
                      suffixIcon: <DropdownIcon />,
                    }}
                    setCreateEmployee={setCreateEmployee}
                    onSelectChange={(val: { value: string }[]) =>
                      checkHoldersJob(val)
                    }
                    // isRequired
                    // rules={{
                    //   required: `${companyData?.label_position} holders is required.`,
                    // }}
                    // validate={errors.jobHolders && "error"}
                    // validMessage={
                    //   errors.jobHolders && errors.jobHolders.message
                    // }
                    tooltipTitle="Employees in your company holding this position."
                  />
                </Col>
                {otherJobsHolder.length > 0 && (
                  <Col span={24} className="warning_alert_popup">
                    <Alert
                      message={
                        <>
                          <b>
                            {otherJobsHolder
                              ?.map((item: { label: string }) => item?.label)
                              ?.join(", ")}
                          </b>
                          {otherJobsHolder.length === 1
                            ? ` occupy other ${
                                companyData?.label_position === "Job"
                                  ? "job"
                                  : "seat"
                              }. Their ${
                                companyData?.label_position === "Job"
                                  ? "job"
                                  : "seat"
                              } will be changed to this one if you proceed.`
                            : ` occupies other ${
                                companyData?.label_position === "Job"
                                  ? "jobs"
                                  : "seats"
                              }. Their ${
                                companyData?.label_position === "Job"
                                  ? "jobs"
                                  : "seats"
                              } will be changed to this one if you proceed.`}
                        </>
                      }
                      type="warning"
                      showIcon
                    />
                  </Col>
                )}

                <Col className="fp_footer">
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="icon_dark_btn"
                    >
                      Create job
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Card>
      <Popup {...createJob} />
    </FullPageLayout>
  );
};
